import { createSelector } from '@reduxjs/toolkit'
import { get } from 'lodash'

import { generateApi } from 'utils/api'
import { generateCrudActions } from 'utils/api/actions'
import { getCurrentUserId } from 'utils/auth'

const { updateUser, useUser, selectUsers } = generateApi('user')
const { fetch: fetchUser } = generateCrudActions('user')

const useCurrentUser = () => {
  const userId = getCurrentUserId()
  const user = useUser({ objectId: userId })
  return user
}

const selectCurrentUser = createSelector(selectUsers, users => {
  const userId = getCurrentUserId()
  return get(users, userId)
})

export { fetchUser, updateUser, useCurrentUser, selectCurrentUser }
