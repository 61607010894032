import React from 'react'

import { useBoolean } from 'utils/state'
import CreateFacebookAccount from 'modules/vendors/facebook'
import AddShopModal from 'modules/vendors/shopify/add_shop'

import { FaShopify } from 'react-icons/fa'

import IntegrationCard from './card'
import './styles.scss'
import GoogleAuthButton from 'modules/vendors/google/auth'
import Card from 'components/card'

// Facebook: Redirect confirmation modal -> redirect to facebook authentication
// Shopify: Shop name modal/redirect confirmation

const ConnectAccounts = () => {
  const [shopifyOpen, openShopify, closeShopify] = useBoolean(false)
  const accounts = [
    {
      name: '',
      icon: <FaShopify size="24px" color="#ffffff" />,
      color: '#96bf48',
      onClick: openShopify,
      disabled: false
    }
  ]
  return (
    <>
      <Card className="connect-accounts-card">
        <div className="title">Add Connections</div>
        <div className="card-content">
          <CreateFacebookAccount />
          {accounts.map((a, i) => (
            <IntegrationCard
              key={i}
              name={a.name}
              icon={a.icon}
              onClick={a.onClick}
              color={a.color}
            />
          ))}
          <GoogleAuthButton />
        </div>
      </Card>
      <AddShopModal open={shopifyOpen} onClose={closeShopify} />
    </>
  )
}

export default ConnectAccounts
