import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core'
import { SortableContext, sortableKeyboardCoordinates } from '@dnd-kit/sortable'
import { moveImmutable } from 'utils/move'
import {
  selectOrderedDashboardItems,
  reorderDashboardItems,
  moveDashboardItems
} from 'modules/dashboard_items/api'
import DashboardItem from 'modules/dashboard_items/components/item'

import './styles.scss'

const DashboardItems = ({ dashboardId }) => {
  const dispatch = useDispatch()
  const dashboardItems = useSelector(state =>
    selectOrderedDashboardItems(state, dashboardId)
  )

  const sensors = useSensors(
    useSensor(PointerSensor, {
      activationConstraint: {
        distance: 5
      }
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  )

  const onDragEnd = event => {
    const { active, over } = event
    if (active.id !== over.id) {
      dispatch(
        reorderDashboardItems({
          activeId: active.id,
          overId: over.id,
          items: dashboardItems
        })
      )

      const updatedItems = moveImmutable({
        items: dashboardItems,
        overId: over.id,
        activeId: active.id,
        orderKey: 'x'
      })

      dispatch(
        moveDashboardItems({
          dashboardId,
          body: {
            items: updatedItems
          }
        })
      )
    }
  }

  return (
    <div className="dashboard-container">
      <DndContext
        onDragEnd={onDragEnd}
        sensors={sensors}
        collisionDetection={closestCenter}
      >
        <SortableContext items={dashboardItems}>
          {dashboardItems.map(item => (
            <DashboardItem key={item.id} id={item.id} dashboardItem={item} />
          ))}
        </SortableContext>
      </DndContext>
    </div>
  )
}

DashboardItems.propTypes = {
  dashboardId: PropTypes.string.isRequired
}
export default DashboardItems
