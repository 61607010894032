import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  ResponsiveContainer,
  Tooltip
} from 'recharts'
import { COLORS } from 'constants/colors'

import CustomTooltip from './tooltip'
import { shadeColor } from './utils'

import './styles.scss'

const getColor = (colors, key) => {
  const color = get(colors, key, '#1d4299')
  if (key === 'target') {
    return '#d9b518'
  }
  if (key === 'previous') {
    return shadeColor(0.25, color)
  }
  if (key === 'movingAverage') {
    return shadeColor(0.5, color)
  }
  return color
}

const Chart = ({
  data,
  keys,
  colors,
  xAxisKey,
  xAxisFormatter,
  yAxisFormatter
}) => {
  const showYAxis = false

  const renderLines = keys => {
    return []
      .concat(keys)
      .map((key, i) => (
        <Line
          key={i}
          type="monotone"
          dataKey={key}
          stroke={getColor(colors, key)}
          strokeWidth={2}
          strokeDasharray={key === 'movingAverage' ? '5 5' : ''}
          dot={false}
        />
      ))
  }

  return (
    <ResponsiveContainer minHeight={100} maxHeight={300}>
      <LineChart data={data}>
        <Tooltip content={<CustomTooltip />} />
        <XAxis
          dataKey={xAxisKey}
          axisLine={false}
          tickLine={false}
          tickFormatter={xAxisFormatter}
          minTickGap={100}
          padding={{ left: 0 }}
        />
        {showYAxis && (
          <YAxis
            axisLine={false}
            tickLine={false}
            tickFormatter={yAxisFormatter}
            width={50}
            minTickGap={25}
          />
        )}
        {renderLines(keys)}
      </LineChart>
    </ResponsiveContainer>
  )
}

Chart.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  keys: PropTypes.arrayOf(PropTypes.string),
  colors: PropTypes.shape({}),
  xAxisKey: PropTypes.string,
  xAxisFormatter: PropTypes.func,
  yAxisFormatter: PropTypes.func
}

Chart.defaultProps = {
  data: [],
  keys: [],
  colors: { default: COLORS.green_main },
  xAxisKey: 'endTime',
  xAxisFormatter: x => x,
  yAxisFormatter: y => y,
  target: undefined
}

export default Chart
