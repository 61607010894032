import React from 'react'
import PropTypes from 'prop-types'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { useSelector } from 'react-redux'
import classNames from 'classnames'

import { selectDashboardItem } from 'modules/dashboard_items/api'
import { selectRangeEnd, selectRangeStart } from 'modules/layout/selectors'

import { useDashboardItemEntries } from 'modules/entries/apis'

import DashboardItemChart from './chart'
import DashboardItemTable from './table'

import './styles.scss'

const DashboardItem = ({ id }) => {
  const start = useSelector(selectRangeStart)
  const end = useSelector(selectRangeEnd)

  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id })

  const dashboardItem = useSelector(state => selectDashboardItem(state, id))
  const { accountMetricIds, element } = dashboardItem

  const style = {
    transform: CSS.Transform.toString(transform),
    transition
  }

  const cssClass = classNames('dashboard-item', `width-4`)

  useDashboardItemEntries({
    parentId: id,
    params: { start, end }
  })

  let content

  if (element === 'chart') {
    content = (
      <DashboardItemChart
        dashboardItemId={id}
        accountMetricIds={accountMetricIds}
        start={start}
        end={end}
      />
    )
  } else if (element === 'table') {
    content = (
      <DashboardItemTable
        dashboardItemId={id}
        accountMetricIds={accountMetricIds}
        start={start}
        end={end}
      />
    )
  }

  return (
    <div
      className={cssClass}
      style={style}
      ref={setNodeRef}
      {...listeners}
      {...attributes}
    >
      {content}
    </div>
  )
}

DashboardItem.propTypes = {
  id: PropTypes.string.isRequired
}

export default DashboardItem
