import { generateApi } from 'utils/api'

const {
  destroyAccount,
  updateAccount,
  selectAccount,
  selectAccounts,
  selectOrganizationAccounts,
  useAccount,
  useAccounts,
  useOrganizationAccounts
} = generateApi('account', ['organization'])

export {
  destroyAccount,
  updateAccount,
  selectAccount,
  selectAccounts,
  selectOrganizationAccounts,
  useAccount,
  useAccounts,
  useOrganizationAccounts
}
