import jwt_decode from 'jwt-decode'

const insertString = (str, index, value) => {
  return str.substr(0, index) + value + str.substr(index)
}

const convertToUUID = str => {
  let _uuid = insertString(str, 8, '-')
  _uuid = insertString(_uuid, 13, '-')
  _uuid = insertString(_uuid, 18, '-')
  _uuid = insertString(_uuid, 23, '-')

  return _uuid
}

const setAuthToken = token => {
  localStorage.setItem('elm_token', token)
}

const getAuthToken = () => {
  const token = localStorage.getItem('elm_token')
  try {
    const { exp } = jwt_decode(token)
    if (exp > Date.now() / 1000) {
      return token
    } else {
      setAuthToken()
    }
  } catch {}

  return null
}

const getCurrentUserId = () => {
  const token = getAuthToken()
  const { id } = jwt_decode(token)
  return convertToUUID(id)
}

export { getAuthToken, setAuthToken, getCurrentUserId }
