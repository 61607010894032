import React from 'react'

import {
  ConnectionsButton,
  MyProfileButton,
  SubscriptionButton
} from 'modules/layout/buttons'

const SettingsTabs = () => {
  return (
    <div className="settings-tabs">
      <MyProfileButton className="tab" />
      <SubscriptionButton className="tab" />
      <ConnectionsButton className="tab" />
    </div>
  )
}

export default SettingsTabs
