import React from 'react'
import { useDispatch } from 'react-redux'

import Button from 'components/button'
import Card from 'components/card'

import { getStripeManageSession } from '../actions'

import './styles.scss'

const StripeManage = () => {
  const dispatch = useDispatch()

  const handleSubmit = () => {
    return dispatch(getStripeManageSession())
  }

  return (
    <Card className="stripe-manage">
      <div className="title">Stripe</div>
      <div className="description">
        We manage our subscriptions through Stripe. Clicking the button below
        will take you to Stripe to manage your Treedash subscription. Please,
        feel free to reach out to our customer service if you have any
        questions.
      </div>
      <Button className="manage" onClick={handleSubmit}>
        Manage my Subscription
      </Button>
    </Card>
  )
}

export default StripeManage
