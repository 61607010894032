import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { selectDashboardItem } from 'modules/dashboard_items/api'
import DivButton from 'components/div_button'
import { MdSettings } from 'react-icons/md'
import './styles.scss'

const DashboardItemTable = ({ dashboardItemId }) => {
  const navigate = useNavigate()
  const dashboardItem = useSelector(state =>
    selectDashboardItem(state, dashboardItemId)
  )
  const { name } = dashboardItem

  const handleSettingsClick = () => {
    navigate(`/dashboard_items/${dashboardItemId}`)
  }

  return (
    <div className="chart-content">
      <div className="header">
        <div className="title">{name}</div>
        <DivButton className="settings-icon" onClick={handleSettingsClick}>
          <MdSettings size="1em" />
        </DivButton>
      </div>

      <div className="item-container">
        <div>Row</div>
      </div>
    </div>
  )
}

DashboardItemTable.propTypes = {
  dashboardItemId: PropTypes.string.isRequired,
  accountMetricIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  target: PropTypes.string
}

DashboardItemTable.defaultProps = {
  target: undefined
}

export default DashboardItemTable
