import { createSelector } from '@reduxjs/toolkit'
import { filter, get, values, orderBy, pick, map } from 'lodash'
import { plural } from 'pluralize'
import { upperCase, pluralUpperCase } from './string'
import { filterCollection } from './filter'

const createPropGetter = property => {
  return (state, props) => get(props, property)
}

const getApi = state => get(state, 'api')

const selectCollection = createSelector(
  getApi,
  createPropGetter('resource'),
  (api, resource) => {
    return get(api, resource, {})
  }
)

const selectIdsFromCollection = createSelector(
  selectCollection,
  createPropGetter('ids'),
  (collection, ids) => {
    return pick(collection, ids)
  }
)

const selectPropertyFromCollection = createSelector(
  selectIdsFromCollection,
  createPropGetter('key'),
  (collection, key) => {
    return map(values(collection), key)
  }
)

const selectObject = createSelector(
  selectCollection,
  createPropGetter('objectId'),
  (collection, objectId) => get(collection, objectId, {})
)

const selectAssociatedCollection = createSelector(
  selectCollection,
  createPropGetter('resource'),
  createPropGetter('parent'),
  createPropGetter('parentId'),
  createPropGetter('params'),
  (collection, resource, parent, parentId, params = {}) => {
    let objects = []
    const parentKey = `${parent}Id`

    if (resource === 'entries') {
      objects = get(collection, parentId)
      if (params) {
        objects = filterCollection(objects, params)
      }
      return orderBy(objects, 'endTime')
    }

    objects = values(collection)
    objects = filter(objects, { [parentKey]: parentId })

    return objects
  }
)

const resourceSelector = resource => state => get(state.api, resource, {})

const objectSelector = resource => (state, objectId) =>
  selectObject(state, { resource, objectId })

const collectionSelector =
  (resource, parent) =>
  (state, parentId, params = {}) =>
    selectAssociatedCollection(state, {
      resource: plural(resource),
      parent,
      parentId,
      params
    })

const generateSelectors = (resource, parents) => {
  const resourceName = plural(resource)
  const parentSelectors = {}

  parents.forEach(parent => {
    parentSelectors[`select${upperCase(parent)}${pluralUpperCase(resource)}`] =
      collectionSelector(resource, parent)
  })
  return {
    [`select${upperCase(resource)}`]: objectSelector(resourceName),
    [`select${pluralUpperCase(resource)}`]: resourceSelector(resourceName),
    ...parentSelectors
  }
}

export {
  createPropGetter,
  generateSelectors,
  selectObject,
  selectCollection,
  selectIdsFromCollection,
  selectPropertyFromCollection,
  selectAssociatedCollection
}
