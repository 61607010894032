import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { size } from 'lodash'

import {
  createOrganizationDashboard,
  selectDashboards
} from 'modules/dashboards/api'

import { FaPlus } from 'react-icons/fa'
import DivButton from 'components/div_button'

import '../styles.scss'

const MAX_DASHBOARDS = 7

const AddDashboardButton = ({ className, organizationId }) => {
  const dispatch = useDispatch()
  const dashboards = useSelector(selectDashboards)
  const count = size(dashboards)
  const handleClick = () => {
    dispatch(
      createOrganizationDashboard({
        body: { organizationId, name: `New Dashboard (${count})` },
        parentId: organizationId
      })
    )
  }

  if (count >= MAX_DASHBOARDS) {
    return null
  }
  return (
    <DivButton
      className={classNames('nav-button', 'child', className)}
      onClick={handleClick}
    >
      <FaPlus className="icon" />
      Add Dashboard
    </DivButton>
  )
}

AddDashboardButton.propTypes = {
  className: PropTypes.string,
  organizationId: PropTypes.string.isRequired
}

AddDashboardButton.defaultProps = {
  className: undefined
}

export default AddDashboardButton
