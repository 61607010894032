import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { useNavigate } from 'react-router-dom'
import { useBoolean } from 'utils/state'
import { AuthContext } from 'app/auth'
import { useQueryParams } from 'utils/query'

import { useAccounts } from 'modules/accounts/api'
import { useOrganizations } from 'modules/organizations/api'
import { useResources } from 'modules/resource/api'
import { useVendors } from 'modules/vendors/api'

import { useCurrentOrganizationId } from '../hooks'

import DivButton from 'components/div_button'
import { MdChevronLeft, MdChevronRight } from 'react-icons/md'

import OrganizationButton from '../buttons/expandable/organizations'
import DashboardButton from '../buttons/expandable/dashboards'
import {
  ConnectionsButton,
  MyProfileButton,
  SubscriptionButton
} from '../buttons'

import './styles.scss'

const SidePanel = ({ isActive }) => {
  useAccounts()
  useVendors()
  useResources()
  const organizations = useOrganizations()
  const { hidePanel } = useQueryParams()
  const [open, show, hide] = useBoolean(!hidePanel)
  const navigate = useNavigate()
  const { handleLogout } = useContext(AuthContext)

  const organizationId = useCurrentOrganizationId(organizations)

  const handleNavigation = location => () => {
    navigate(location)
  }

  if (!isActive) {
    return null
  }

  const content = open ? (
    <div className="side-panel">
      <div className="side-panel-content">
        <div className="header">
          <DivButton className="side-panel-row" onClick={handleNavigation('/')}>
            <div className="title">Home</div>
          </DivButton>
          <DivButton className="toggle-button" onClick={hide}>
            <MdChevronLeft size="2em" />
          </DivButton>
        </div>
        <div className="main">
          <DashboardButton
            className="side-panel-row"
            organizationId={organizationId}
          />
        </div>
        <div className="footer">
          <OrganizationButton className="side-panel-row" />
          <MyProfileButton className="side-panel-row" />
          <ConnectionsButton className="side-panel-row" />
          <SubscriptionButton className="side-panel-row" />
        </div>
        <DivButton className="side-panel-signout" onClick={handleLogout}>
          Sign Out
        </DivButton>
      </div>
    </div>
  ) : (
    <div className="side-panel">
      <DivButton onClick={show} className="side-panel-show">
        <MdChevronRight size="2em" />
      </DivButton>
    </div>
  )

  return content
}

SidePanel.propTypes = {
  isActive: PropTypes.bool.isRequired
}

export default SidePanel
