import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

import { updateDashboard } from 'modules/dashboards/api'

import Editable from 'components/input/editable'

const DashboardTitle = ({ dashboardId, name }) => {
  const dispatch = useDispatch()
  const updateDashboardName = name => {
    dispatch(updateDashboard({ objectId: dashboardId, body: { name } }))
  }
  return (
    <Editable
      className="dashboard-title"
      value={name}
      setValue={updateDashboardName}
    />
  )
}

DashboardTitle.propTypes = {
  dashboardId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired
}

export default DashboardTitle
