import { request } from 'utils/api/request'

const authenticateFacebook = body => {
  return request({ body, path: '/facebook/authenticate', method: 'POST' })
}

const authenticateGoogle = body => {
  return request({ body, path: '/google/authenticate', method: 'POST' })
}

const authenticateShopify = body => {
  return request({ body, path: '/shopify/authenticate', method: 'POST' })
}

const confirmShopify = body => {
  return request({ body, path: '/shopify/confirm', method: 'POST' })
}

export {
  authenticateFacebook,
  authenticateGoogle,
  authenticateShopify,
  confirmShopify
}
