import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'

import { selectAccount, useAccounts } from 'modules/accounts/api'
import { useResourceMetrics } from 'modules/metrics/api'
import { createDashboardDashboardItem } from 'modules/dashboard_items/api'
import { createDashboardItemMetric } from 'modules/dashboard_item_metrics/api'

import Modal from 'components/modal'
import Button from 'components/button'
import SelectField from 'components/input/select'

import './styles.scss'

const DEFAULT_CHART_WIDTH = 4
const DEFAULT_KPI_WIDTH = 3

const SelectMetric = ({ accountId, metricId, setMetricId }) => {
  const account = useSelector(state => selectAccount(state, accountId))
  const { resourceId } = account
  const metrics = useResourceMetrics({ parentId: resourceId })
  const metricOptions = metrics.map(m => ({
    value: m.id,
    label: m.name
  }))
  return (
    <SelectField
      placeholder="Select Metric"
      options={metricOptions}
      value={metricId}
      onChange={e => setMetricId(e.value)}
      disabled={!accountId}
    />
  )
}

const CreateDashboardItemModal = ({
  dashboardId,
  dashboardItemId,
  open,
  onClose
}) => {
  const dispatch = useDispatch()
  const accounts = useAccounts()
  const accountOptions = accounts.map(account => ({
    value: account.id,
    label: account.name
  }))
  const [accountId, setAccountId] = useState()
  const [metricId, setMetricId] = useState()
  const [element, setElement] = useState()
  const elementOptions = [
    { label: 'Chart', value: 'chart' },
    { label: 'KPI', value: 'kpi' },
    { label: 'Table', value: 'table' }
  ]
  const width = element === 'chart' ? DEFAULT_CHART_WIDTH : DEFAULT_KPI_WIDTH
  const handleClose = () => {
    setAccountId()
    setMetricId()
    setElement()
    onClose()
  }
  const action = dashboardId
    ? createDashboardDashboardItem
    : createDashboardItemMetric

  const handleSubmit = () => {
    const payload = dashboardId
      ? {
          body: {
            dashboardId,
            account_metrics: [{ accountId, metricId }],
            element,
            width
          },
          parentId: dashboardId
        }
      : {
          body: {
            dashboardItemId,
            accountId,
            metricId
          },
          parentId: dashboardItemId
        }

    dispatch(action(payload)).then(resp => {
      const status = get(resp, 'status')
      if (status === 201) {
        handleClose()
      }
    })
  }

  return (
    <Modal open={open} onClose={onClose}>
      <div className="dashboard-item-modal">
        <div className="title">Add to your Dashboard</div>
        <div className="modal-content">
          <div className="row">
            <div className="selection">
              <SelectField
                placeholder="Select Account"
                value={accountId}
                options={accountOptions}
                onChange={e => setAccountId(e.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="selection">
              <SelectMetric
                accountId={accountId}
                metricId={metricId}
                setMetricId={setMetricId}
              />
            </div>
          </div>
          <div className="row">
            <div className="selection">
              <SelectField
                placeholder="Select Type"
                value={element}
                options={elementOptions}
                onChange={e => setElement(e.value)}
                disabled={!metricId}
              />
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </div>
      </div>
    </Modal>
  )
}

CreateDashboardItemModal.propTypes = {
  dashboardId: PropTypes.string,
  dashboardItemId: PropTypes.string,
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

CreateDashboardItemModal.defaultProps = {
  dashboardId: undefined,
  dashboardItemId: undefined
}

export default CreateDashboardItemModal
