import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { get, size } from 'lodash'

import { signUp } from 'api/auth'
import { setAuthToken } from 'utils/auth'

import Button from 'components/button'
import TextField from 'components/input/text'
import Checkbox from 'components/input/checkbox'
import Card from 'components/card'

import './styles.scss'

const SignUp = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [firstName, setFirstName] = useState('')
  const [lastName, setLastName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [hasAgreedToTerms, setHasAgreedToTerms] = useState(false)
  const [error, setError] = useState()

  const handleAgreeToTerms = () => {
    setHasAgreedToTerms(!hasAgreedToTerms)
  }

  const validPassword = pass => size(pass) >= 8

  const isValidForm =
    email && firstName && lastName && size(password) >= 8 && hasAgreedToTerms

  const handleSubmit = event => {
    event.preventDefault()
    if (isValidForm) {
      dispatch(signUp({ firstName, lastName, email, password })).then(resp => {
        const status = get(resp, 'status')
        if (status === 201) {
          const token = get(resp, 'data.token')
          if (token) {
            setAuthToken(token)
            setIsAuthenticated(true)
            navigate('/')
          }
        } else {
          setError(
            'Unable to signup, please double check your email and password!'
          )
        }
      })
    } else {
      setError('Please, complete the form and accept the terms of use!')
    }
  }

  return (
    <Card className="auth-card">
      <div className="title">Sign up</div>
      {error && <div className="auth-message error">{error}</div>}
      <form className="auth-content" onSubmit={handleSubmit}>
        <div sm={6} className="auth-input">
          <TextField
            autoFocus
            required
            autoComplete="given-name"
            name="firstName"
            label="First Name"
            value={firstName}
            onChange={setFirstName}
            error={error && !firstName}
          />
        </div>
        <div sm={6} className="auth-input">
          <TextField
            required
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            value={lastName}
            onChange={setLastName}
            error={error && !lastName}
          />
        </div>
        <div className="auth-input">
          <TextField
            required
            label="Email Address"
            name="email"
            autoComplete="email"
            value={email}
            onChange={setEmail}
            error={error && !email}
          />
        </div>
        <div className="auth-input">
          <TextField
            required
            name="password"
            label="Password"
            type="password"
            autoComplete="new-password"
            value={password}
            onChange={setPassword}
            error={error && !validPassword(password)}
          />
        </div>
        <div className="auth-terms">
          <Checkbox
            id="checkbox-agreed"
            value={hasAgreedToTerms}
            onChange={handleAgreeToTerms}
          />
          Accept terms of use
        </div>
        <div className="auth-buttons">
          <Button className="wide-button" type="submit" variant="primary">
            Sign Up
          </Button>
        </div>
      </form>
    </Card>
  )
}

export default SignUp
