import React from 'react'
import { useLocation } from 'react-router-dom'

import Card from 'components/card'

import './styles.scss'

const ConfirmEmail = () => {
  const { state } = useLocation()
  const { email } = state
  return (
    <Card className="auth-card">
      <div className="title">Thank you!</div>

      <div className="description">
        You have successfully signed up for Treedash and we have sent a
        verification email to {email}. Please, find the email and follow the
        link to continue creating your account.
      </div>
    </Card>
  )
}

export default ConfirmEmail
