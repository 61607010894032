import { SET_RANGE_START, SET_RANGE_END, SET_CURRENT_ORG_ID } from './dux'

const setRangeStart = rangeStart => ({
  type: SET_RANGE_START,
  rangeStart
})

const setRangeEnd = rangeEnd => ({
  type: SET_RANGE_END,
  rangeEnd
})

const setCurrentOrganizationId = organizationId => ({
  type: SET_CURRENT_ORG_ID,
  organizationId
})

export { setRangeEnd, setRangeStart, setCurrentOrganizationId }
