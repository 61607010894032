import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { dateFormatter } from 'utils/datetime'
import { bigNumberFormatter } from 'utils/numbers'

import { selectAccountMetricsEntries } from 'modules/entries/apis'
import { selectDashboardItem } from 'modules/dashboard_items/api'
import { selectAccountMetricColors } from 'modules/account_metrics/api'

import Chart from 'components/dashboard/chart'
import DivButton from 'components/div_button'
import { MdSettings } from 'react-icons/md'
import './styles.scss'
import VendorIcons from 'modules/vendors/components/vendor_icons'
import { selectPropertyFromCollection } from 'utils/api/selectors'

const DashboardItemChart = ({
  dashboardItemId,
  accountMetricIds,
  start,
  end,
  target
}) => {
  const navigate = useNavigate()
  const dashboardItem = useSelector(state =>
    selectDashboardItem(state, dashboardItemId)
  )
  const { name } = dashboardItem
  const entries = useSelector(state =>
    selectAccountMetricsEntries(state, { accountMetricIds, start, end })
  )

  const colors = useSelector(state =>
    selectAccountMetricColors(state, { accountMetricIds })
  )

  const handleSettingsClick = () => {
    navigate(`/dashboard_items/${dashboardItemId}`)
  }

  const vendorIds = useSelector(state =>
    selectPropertyFromCollection(state, {
      ids: accountMetricIds,
      resource: 'accountMetrics',
      key: 'vendorId'
    })
  )

  const data = useMemo(() => {
    if (target) {
      return entries.map(e => {
        e['target'] = Number(target)
        return e
      })
    }
    return entries
  })

  return (
    <div className="chart-content">
      <div className="header">
        <div className="title">{name}</div>
        <DivButton className="settings-icon" onClick={handleSettingsClick}>
          <MdSettings size="1em" />
        </DivButton>
      </div>

      <div className="item-container">
        <Chart
          keys={accountMetricIds}
          data={data}
          colors={colors}
          xAxisKey={'endTime'}
          xAxisFormatter={dateFormatter}
          yAxisFormatter={bigNumberFormatter}
        />
      </div>
      <div className="item-footer">
        <VendorIcons vendorIds={vendorIds} />
      </div>
    </div>
  )
}

DashboardItemChart.propTypes = {
  dashboardItemId: PropTypes.string.isRequired,
  accountMetricIds: PropTypes.arrayOf(PropTypes.string).isRequired,
  start: PropTypes.string.isRequired,
  end: PropTypes.string.isRequired,
  target: PropTypes.string
}

DashboardItemChart.defaultProps = {
  target: undefined
}

export default DashboardItemChart
