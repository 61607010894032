import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'

import { createOrganizationDashboard } from 'modules/dashboards/api'
import { createOrganization } from 'modules/organizations/api'
import { selectCurrentOrganizationId } from 'modules/layout/selectors'
import { useCurrentUser } from 'modules/users/api'

import Card from 'components/card'
import Button from 'components/button'
import TextField from 'components/input/text'
import Stripe from 'modules/stripe/components'
import ConnectAccounts from 'modules/accounts/components/connect'

import './styles.scss'
import { selectAccounts } from 'modules/accounts/api'

const FirstOrganization = () => {
  const dispatch = useDispatch()
  const [name, setName] = useState('')

  const handleSubmit = () => {
    dispatch(createOrganization({ body: { name } })).then(resp => {
      const status = get(resp, 'status')
      const organizationId = get(resp, 'data.id')
      if (status === 201) {
        dispatch(
          createOrganizationDashboard({
            body: { organizationId, name },
            parentId: organizationId
          })
        ).then(resp => {
          const status = get(resp, 'status')
          if (status === 201) {
            setName()
          }
        })
      }
    })
  }

  return (
    <Card className="auth-card">
      <div className="title">Welcome to Treedash!</div>
      <div className="description">What is your organization name?</div>
      <div className="auth-input">
        <TextField
          value={name}
          onChange={setName}
          label="Organization Name"
          placeholder="Scranton Paper Company"
        />
      </div>
      <div className="auth-footer">
        <Button className="wide-button" onClick={handleSubmit}>
          Submit
        </Button>
      </div>
    </Card>
  )
}

const Welcome = () => {
  const organizationId = useSelector(selectCurrentOrganizationId)
  const user = useCurrentUser()
  const accounts = useSelector(selectAccounts)
  const { isActive } = user
  if (!organizationId) {
    return <FirstOrganization />
  }
  if (!isActive) {
    return <Stripe />
  }
  if (isEmpty(accounts)) {
    return <ConnectAccounts />
  }
  return <div>HI</div>
}

export default Welcome
