import React, { useState } from 'react'
import parseISO from 'date-fns/parseISO'
import format from 'date-fns/format'

import { useCurrentRange } from '../hooks'
import DateBubble from './bubble'

import './styles.scss'

const ActionBar = () => {
  const [startDate, endDate] = useCurrentRange()

  const startDisplay =
    startDate && format(parseISO(`${startDate}T00:00:00`), 'M/d/yyyy')
  const endDisplay =
    endDate && format(parseISO(`${endDate}T00:00:00`), 'M/d/yyyy')

  const [selectedBubble, setSelectedBubble] = useState('1m')
  const options = [
    {
      label: '7d',
      unit: 'day',
      increment: 7
    },
    {
      label: '14d',
      unit: 'day',
      increment: 14
    },
    {
      label: '1m',
      unit: 'month',
      increment: 1
    },
    {
      label: '1q',
      unit: 'month',
      increment: 3
    },
    {
      label: '6m',
      unit: 'month',
      increment: 6
    },
    {
      label: '1y',
      unit: 'month',
      increment: 12
    }
  ]

  return (
    <div className="action-bar">
      <div className="action-bar-row">
        <div>
          {startDisplay} - {endDisplay}
        </div>
        <div className="date-options">
          {options.map(o => (
            <DateBubble
              key={o.label}
              label={o.label}
              unit={o.unit}
              increment={o.increment}
              setValue={setSelectedBubble}
              value={selectedBubble}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default ActionBar
