import React from 'react'
import PropTypes from 'prop-types'

import './styles.scss'

const Checkbox = ({ id, disabled, onChange, value }) => {
  return (
    <input
      type="checkbox"
      id={id}
      onChange={onChange}
      checked={value}
      disabled={disabled}
    />
  )
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.bool,
  disabled: PropTypes.bool
}

Checkbox.defaultProps = {
  value: false,
  disabled: false
}

export default Checkbox
