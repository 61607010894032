import React, { useEffect } from 'react'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { useQueryParams } from 'utils/query'
import { useDispatch } from 'react-redux'

import { confirmStripeSession } from '../actions'

import Loading from 'components/loading'
import StripeCheckout from './checkout'

const Stripe = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const queryParams = useQueryParams()
  const wasSuccess = get(queryParams, 'success', false)
  const sessionId = get(queryParams, 'session_id')

  useEffect(() => {
    if (wasSuccess) {
      dispatch(confirmStripeSession({ body: { sessionId } })).then(() => {
        navigate('/')
      })
    }
  })

  if (!wasSuccess && !sessionId) {
    return <StripeCheckout />
  }
  return <Loading />
}

export default Stripe
