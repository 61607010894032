import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import getStore from 'store/configure'
import { Provider } from 'react-redux'

import ShopifyProvider from './shopify'
import AppRoutes from './routes'
import { AuthProvider } from './auth'

import './style.scss'

const App = () => {
  const store = getStore()

  return (
    <Provider store={store}>
      <BrowserRouter>
        <div id="app">
          <ShopifyProvider>
            <AuthProvider>
              <AppRoutes />
            </AuthProvider>
          </ShopifyProvider>
        </div>
      </BrowserRouter>
    </Provider>
  )
}

export default App
