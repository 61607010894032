import React from 'react'
import PropTypes from 'prop-types'

import Navbar from 'modules/layout/navigation/nav_bar'

import './styles.scss'

const Layout = ({ children }) => {
  return (
    <div className="layout">
      <Navbar />
      <div className="container">
        <div className="background">
          <img src={process.env.PUBLIC_URL + '/images/background.png'} />
        </div>
        <div className="content">{children}</div>
      </div>
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
}

Layout.defaultProps = {}

export default Layout
