import React from 'react'
import PropTypes from 'prop-types'

import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { selectVendor } from 'modules/vendors/api'
import { VENDOR_ICONS } from './constants'

const VendorIcon = ({ vendorId }) => {
  const vendor = useSelector(state => selectVendor(state, vendorId))
  const { color, name } = vendor
  const icon = get(VENDOR_ICONS, `${name}.icon`)
  if (icon) {
    return <div style={{ color }}>{icon()}</div>
  }
  return null
}

VendorIcon.propTypes = {
  vendorId: PropTypes.string.isRequired
}

export default VendorIcon
