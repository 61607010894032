import { request } from 'utils/api/request'

const createStripeSession = ({ body }) => {
  return request({
    method: 'POST',
    path: '/stripe/subscribe',
    body
  })
}

const getStripeManageSession = () => {
  return request({
    method: 'GET',
    path: '/stripe/manage'
  })
}

const confirmStripeSession = ({ body }) => {
  return request({
    method: 'POST',
    path: '/stripe/confirm',
    body
  })
}

export { createStripeSession, getStripeManageSession, confirmStripeSession }
