import { useJobs } from 'modules/jobs/api'
import React from 'react'
import DestroyJob from '../destroy'

import './styles.scss'

const ListJobs = () => {
  const jobs = useJobs()

  return (
    <div className="list-jobs">
      {jobs.map((job, i) => (
        <div className="job" key={i}>
          <div>{job.id}</div>
          <div>{job.name}</div>
          <div>{job.args}</div>
          <div>{job.scheduledFor}</div>

          <DestroyJob id={job.id} />
        </div>
      ))}
    </div>
  )
}

export default ListJobs
