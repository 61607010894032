import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import { destroyAccount } from 'modules/accounts/api'

import Button from 'components/button'

import AccountIcon from './icon'
import AccountHealth from '../health'

import './styles.scss'

const AccountListRow = ({ account }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id, name, healthy, resourceId } = account

  const handleDelete = () => {
    dispatch(destroyAccount({ objectId: id }))
  }

  const handleManage = () => {
    navigate(`/accounts/${id}`)
  }

  return (
    <div className="account-list-row">
      <div className="section">
        <AccountHealth healthy={healthy} iconSize="24px" />
        <AccountIcon resourceId={resourceId} />
        <div className="title">{name}</div>
      </div>

      <div className="section">
        <Button
          className="edit-account"
          variant="primary"
          onClick={handleManage}
        >
          Manage
        </Button>
        <Button
          className="edit-account"
          variant="danger"
          onClick={handleDelete}
        >
          Delete
        </Button>
      </div>
    </div>
  )
}

AccountListRow.propTypes = {
  account: PropTypes.shape({}),
  organizations: PropTypes.arrayOf(PropTypes.shape({}))
}

export default AccountListRow
