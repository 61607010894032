import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { useQueryParams } from 'utils/query'

import { validateEmail } from 'api/auth'

import Card from 'components/card'
import Loading from 'components/loading'

import './styles.scss'

const ValidateEmail = () => {
  const dispatch = useDispatch()
  const [confirmedEmail, setConfirmedEmail] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const params = useQueryParams()
  const { token } = params

  useEffect(() => {
    if (token) {
      dispatch(validateEmail({ token })).then(resp => {
        const { status } = resp
        if (status === 200) {
          setConfirmedEmail(true)
        }
        setIsLoading(false)
      })
    }
  }, [])

  if (isLoading) {
    return <Loading />
  }

  return (
    <Card className="confirm">
      {confirmedEmail ? (
        <div className="title">
          Thank you for confirming your email!
          <Link to="/signin">Log In!</Link>
        </div>
      ) : (
        <div className="title">
          Error confirming your email, please try again.
        </div>
      )}
    </Card>
  )
}

export default ValidateEmail
