import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { first, isEmpty, keys } from 'lodash'

import { selectAccounts } from 'modules/accounts/api'
import { selectOrganizations } from 'modules/organizations/api'
import { selectDashboards } from 'modules/dashboards/api'

import Welcome from 'modules/users/components/auth/welcome'
import Loading from 'components/loading'

const Home = () => {
  const navigate = useNavigate()
  const [dashboardId, setDashboardId] = useState()
  const organizations = useSelector(selectOrganizations)
  const accounts = useSelector(selectAccounts)
  const dashboards = useSelector(selectDashboards)

  useEffect(() => {
    if (dashboards) {
      const id = first(keys(dashboards))
      setDashboardId(id)
    }
  })

  if (isEmpty(organizations) || isEmpty(accounts)) {
    return <Welcome />
  }

  if (dashboardId) {
    navigate(`/dashboards/${dashboardId}`)
  }

  return <Loading />
}
export default Home
