import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { plural } from 'pluralize'
import { get, values } from 'lodash'
import { generateCrudActions } from './actions'
import {
  selectObject,
  selectAssociatedCollection,
  selectCollection
} from './selectors'
import { pluralUpperCase, upperCase } from './string'

const generateObjectHook = (action, resource) => {
  const useObject = ({ objectId, params = {}, options = {} }) => {
    const { start, end } = params
    const dispatch = useDispatch()
    const object = useSelector(state =>
      selectObject(state, { resource: plural(resource), objectId })
    )
    useEffect(() => {
      if (objectId) {
        dispatch(
          action({ objectId, params: { start, end, ...params }, options })
        )
      }
    }, [dispatch, objectId, start, end])

    return object
  }
  return useObject
}
const generateAssociatedCollectionHook = (action, resource, parent) => {
  const useAssociatedCollection = props => {
    const parentId = get(props, 'parentId', undefined)
    const start = get(props, 'params.start', undefined)
    const end = get(props, 'params.end', undefined)
    const options = get(props, 'options', undefined)

    const dispatch = useDispatch()
    const objects = useSelector(state =>
      selectAssociatedCollection(state, {
        resource: plural(resource),
        parent,
        ...props
      })
    )
    useEffect(() => {
      if (parent && parentId) {
        dispatch(action({ parentId, params: { start, end }, options }))
      }
    }, [parentId, start, end])

    return values(objects)
  }
  return useAssociatedCollection
}

const generateCollectionHook = (action, resource) => {
  const useCollectionHook = props => {
    const dispatch = useDispatch()
    const objects = useSelector(state => selectCollection(state, { resource }))

    useEffect(() => {
      dispatch(action({ ...props }))
    }, [])

    return values(objects)
  }
  return useCollectionHook
}

const generateHooks = (resource, parents = []) => {
  const hooks = {}
  const { fetch, fetchAll } = generateCrudActions(resource)

  hooks[`use${pluralUpperCase(resource)}`] = generateCollectionHook(
    fetchAll,
    plural(resource)
  )

  parents.forEach(parent => {
    const { fetchAll } = generateCrudActions(resource, parent)
    hooks[`use${upperCase(parent)}${pluralUpperCase(resource)}`] =
      generateAssociatedCollectionHook(fetchAll, resource, parent)
  })

  return {
    ...hooks,
    [`use${upperCase(resource)}`]: generateObjectHook(fetch, resource)
  }
}

export { generateHooks }
