import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/button'
import './styles.scss'

const IntegrationCard = ({ name, onClick, icon, color }) => {
  const style = { backgroundColor: color }
  return (
    <Button className="integration-card" onClick={onClick} style={style}>
      {icon}
      <div>{name}</div>
    </Button>
  )
}

IntegrationCard.propTypes = {
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  icon: PropTypes.node.isRequired,
  color: PropTypes.string.isRequired
}

export default IntegrationCard
