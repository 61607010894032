import React, { useState, useEffect } from 'react'
import { isEmpty } from 'lodash'

import { useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useBoolean } from 'utils/state'

import {
  destroyDashboardItem,
  updateDashboardItem,
  useDashboardItem
} from 'modules/dashboard_items/api'
import { useDashboardItemEntries } from 'modules/entries/apis'
import { selectRangeEnd, selectRangeStart } from 'modules/layout/selectors'
import { deleteDashboardItemMetric } from 'modules/dashboard_item_metrics/api'

import Card from 'components/card'
import TextField from 'components/input/text'
import Button from 'components/button'
import SelectField from 'components/input/select'
import AccountMetricChart from 'modules/account_metrics/components/chart.js'

import CreateDashboardItemModal from '../create'
import './styles.scss'

const ManageDashboardItem = () => {
  const dispatch = useDispatch()
  const [open, onOpen, onClose] = useBoolean(false)
  const { dashboardItemId } = useParams()
  const start = useSelector(selectRangeStart)
  const end = useSelector(selectRangeEnd)

  useDashboardItemEntries({
    parentId: dashboardItemId,
    params: { start, end }
  })

  const dashboardItem = useDashboardItem({
    objectId: dashboardItemId
  })

  const {
    name: initialName,
    accountMetricIds,
    element: initialElement
  } = dashboardItem

  const [name, setName] = useState()
  const [element, setElement] = useState(initialElement)

  useEffect(() => {
    if (initialName) {
      setName(initialName)
      setElement(initialElement)
    }
  }, [initialName])

  const handleUpdate = () => {
    dispatch(
      updateDashboardItem({
        objectId: dashboardItemId,
        body: { name, element }
      })
    )
  }

  const handleDelete = () => {
    dispatch(destroyDashboardItem({ objectId: dashboardItemId }))
  }

  const handleRemoveAccountMetric = id => () => {
    dispatch(deleteDashboardItemMetric(id))
  }

  if (isEmpty(dashboardItem)) {
    return null
  }

  const elementOptions = [
    {
      value: 'chart',
      label: 'Chart'
    },
    {
      value: 'table',
      label: 'Table'
    }
  ]

  return (
    <>
      <Card className="dashboard-item-card">
        <div className="section-title">Information</div>
        <div className="card-header">
          <div>
            <TextField
              value={name || initialName}
              onChange={setName}
              label="Name"
            />
            <SelectField
              placeholder="Select Type"
              value={element}
              options={elementOptions}
              onChange={e => setElement(e.value)}
            />
          </div>
          <Button variant="primary" onClick={handleUpdate}>
            Save
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Delete
          </Button>
        </div>
      </Card>
      <Button className="add-dashboard-item" onClick={onOpen}>
        Add
      </Button>
      <CreateDashboardItemModal
        dashboardItemId={dashboardItemId}
        open={open}
        onClose={onClose}
      />
      <Card>
        <div className="section-title">Metrics</div>
        <div className="dashboard-container">
          {accountMetricIds.map(am => (
            <div className="dashboard-item width-6">
              <AccountMetricChart accountMetricId={am} />
              <Button onClick={handleRemoveAccountMetric(am.id)}>Delete</Button>
            </div>
          ))}
        </div>
      </Card>
    </>
  )
}

export default ManageDashboardItem
