import { authenticateFacebook } from 'api/integrations'
import React from 'react'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { FaFacebook } from 'react-icons/fa'
import { useDispatch } from 'react-redux'

import './styles.scss'

const CreateFacebookAccount = () => {
  const dispatch = useDispatch()

  const handleResponse = body => {
    dispatch(authenticateFacebook(body))
  }

  const handleRender = ({ onClick }) => {
    const handleClick = () => {
      onClick()
    }

    return (
      <button className="facebook-button" onClick={handleClick}>
        <FaFacebook size="24px" />
      </button>
    )
  }

  return (
    <FacebookLogin
      appId={process.env.REACT_APP_FACEBOOK_APP_ID}
      autoLoad={false}
      fields="name,email,picture"
      scope={process.env.REACT_APP_FACEBOOK_SCOPES}
      callback={handleResponse}
      render={handleRender}
    />
  )
}

export default CreateFacebookAccount
