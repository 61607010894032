// https://stackoverflow.com/questions/5560248/programmatically-lighten-or-darken-a-hex-color-or-rgb-and-blend-colors

const shadeColor = (percent, color) => {
  const R = parseInt(color.substring(1, 3), 16)
  const G = parseInt(color.substring(3, 5), 16)
  const B = parseInt(color.substring(5, 7), 16)
  return `rgb(${R}, ${G}, ${B}, ${percent})`
}

export { shadeColor }
