import { request } from 'utils/api/request'

const login = body => {
  return request({ body, path: '/login', method: 'POST' })
}

const logout = body => {
  return request({ body, path: '/logout', method: 'POST' })
}

const signUp = body => {
  return request({ body, path: '/signup', method: 'POST' })
}

const forgotPassword = body => {
  return request({ body, path: '/forgot', method: 'POST' })
}

const resetPassword = body => {
  return request({ body, path: '/reset', method: 'POST' })
}

const validateEmail = body => {
  return request({ body, path: '/validate', method: 'POST' })
}

export { login, logout, signUp, forgotPassword, resetPassword, validateEmail }
