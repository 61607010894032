import React from 'react'
import { useDispatch } from 'react-redux'

import { googleRedirect } from 'modules/vendors/actions'

import Button from 'components/button'

import './styles.scss'

const GoogleAuthButton = () => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(googleRedirect())
  }
  return (
    <Button variant="primary-outline" className="google" onClick={handleClick}>
      <img src={process.env.PUBLIC_URL + '/images/google.png'} />
    </Button>
  )
}

export default GoogleAuthButton
