import React from 'react'
import { useDispatch } from 'react-redux'
import { createScheduleJob } from 'modules/jobs/api'
import Button from 'components/button'

const CreateScheduleJob = () => {
  const dispatch = useDispatch()
  const handleClick = () => dispatch(createScheduleJob({}))

  return (
    <div className="create-job">
      <Button onClick={handleClick}>Start</Button>
    </div>
  )
}

export default CreateScheduleJob
