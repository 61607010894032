import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { useCurrentRange } from 'modules/layout/hooks'
import ActionBar from 'modules/layout/action_bar'
import { useAccountMetricEntries } from 'modules/entries/apis'

import EntryRow from './row'
import { filter, get, startsWith } from 'lodash'

import './styles.scss'
import { useSelector } from 'react-redux'
import { selectAccountMetric } from 'modules/account_metrics/api'

const EntryList = ({ accountMetricId }) => {
  const accountMetric = useSelector(state =>
    selectAccountMetric(state, accountMetricId)
  )
  const target = get(accountMetric, 'targetAttributes.value')
  const [start, end] = useCurrentRange()

  const entries = useAccountMetricEntries({
    parentId: accountMetricId,
    params: { start, end }
  })

  const filteredEntries = useMemo(() => {
    return filter(entries, entry => !startsWith(entry.id, 'missing'))
  }, [entries, start, end])

  return (
    <div>
      <ActionBar />
      <div className="entry-list">
        {filteredEntries.map(entry => (
          <EntryRow
            accountMetricId={accountMetricId}
            entryId={entry.id}
            globalTarget={target}
          />
        ))}
      </div>
    </div>
  )
}

EntryList.propTypes = {
  accountMetricId: PropTypes.string.isRequired
}

export default EntryList
