import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { get } from 'lodash'

import {
  selectAccountMetric,
  updateAccountMetric
} from 'modules/account_metrics/api'

import Editable from 'components/input/editable'
import Card from 'components/card'
import EntryList from 'modules/entries/components/entry_list'

import './styles.scss'

const ManageAccountMetric = () => {
  const dispatch = useDispatch()
  const { accountMetricId } = useParams()
  const accountMetric = useSelector(state =>
    selectAccountMetric(state, accountMetricId)
  )
  const { targetAttributes, metricName, lastFetchedAt } = accountMetric
  const initialTarget = get(targetAttributes, 'value', 0)
  const [target, setTarget] = useState(initialTarget)

  const handleChange = newValue => {
    setTarget(newValue)
    dispatch(
      updateAccountMetric({
        objectId: accountMetricId,
        body: {
          targetAttributes: {
            value: newValue
          }
        },
        options: { toast: 'Successfully Updated Target.' }
      })
    )
  }

  return (
    <Card>
      <div className="header">
        <div>{metricName}</div>
        <div>{lastFetchedAt}</div>
      </div>

      <div className="section">
        <div className="section-title">Global Target</div>
        <Editable value={target} setValue={handleChange} />
      </div>

      <div className="section">
        <div className="section-title">Data</div>
        <EntryList accountMetricId={accountMetricId} />
      </div>
    </Card>
  )
}

ManageAccountMetric.propTypes = {}

export default ManageAccountMetric
