import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { selectResource } from 'modules/resource/api'
import { selectVendor } from 'modules/vendors/api'

import './styles.scss'

const AccountIcon = ({ resourceId }) => {
  const resource = useSelector(state => selectResource(state, resourceId))
  const { name: resourceName, vendorId } = resource
  const vendor = useSelector(state => selectVendor(state, vendorId))
  const { color } = vendor

  const style = { backgroundColor: color }
  return (
    <div className="resource-icon" style={style}>
      {resourceName}
    </div>
  )
}

AccountIcon.propTypes = {
  resourceId: PropTypes.string.isRequired
}

export default AccountIcon
