import React from 'react'
import { isEmpty, values } from 'lodash'

import { useAccounts } from 'modules/accounts/api'
import { useResources } from 'modules/resource/api'

import Card from 'components/card'

import AccountListRow from './row'
import './styles.scss'

const ListAccounts = () => {
  useResources()
  const accounts = values(useAccounts())
  return (
    <Card className="list-accounts-card">
      <div className="title">Your Connections</div>
      <div className="card-content">
        {isEmpty(accounts) && (
          <div className="list-accounts-empty">
            Be sure to add your connections to get the most out of Treedash.
          </div>
        )}
        {accounts.map(account => (
          <AccountListRow key={account.id} account={account} />
        ))}
      </div>
    </Card>
  )
}

ListAccounts.propTypes = {}

export default ListAccounts
