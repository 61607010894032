const bigNumberFormatter = value => {
  if (value < 1000) {
    return value
  }
  if (value >= 1000000) {
    return `${(value / 1000000).toPrecision(3)}M`
  }
  return value
}

export { bigNumberFormatter }
