import axios from 'axios'
import { get } from 'lodash'
import { getAuthToken, setAuthToken } from 'utils/auth'
import {
  API_URL,
  TIMEOUT,
  ACTIONS,
  REQUEST_STATES,
  DEFAULT_TOAST_DURATION
} from './constants'

const checkCache = state => {
  return get(state, 'NOT_IMPLEMENTED')
}
const apiReceive = (resource, method, json, parentId) => {
  return {
    type: ACTIONS.RECEIVE,
    resource,
    method,
    data: json,
    parentId
  }
}

const requester = axios.create({
  baseURL: API_URL,
  timeout: TIMEOUT,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Headers':
      'Origin, X-Requested-With, Content-Type, Accept'
  }
})

const transitionRequest = (dispatch, url, requestState) => {
  return dispatch({
    type: ACTIONS.STATE_TRANSITION,
    url,
    requestState
  })
}

const request = ({
  resource,
  method = 'GET',
  path,
  body = {},
  params = {},
  parentId = undefined,
  options = {}
}) => {
  let resp
  let url = path
  return (dispatch, getState) => {
    const state = getState()
    const token = getAuthToken()
    const Authorization = `Bearer ${token}`
    const toast = get(options, 'toast')

    request.headers
    if (checkCache(state, url)) {
      return
    }
    // CACHING LOGIC

    transitionRequest(dispatch, url, REQUEST_STATES.BEGIN)
    return requester({
      method,
      url,
      data: body,
      params,
      headers: { Authorization }
    })
      .then(response => {
        resp = response
        const { data } = response
        const redirect = get(data, 'redirect')
        if (redirect) {
          window.location = redirect
          return Promise.resolve(true)
        }
        return Promise.resolve(data)
      })
      .then(data => dispatch(apiReceive(resource, method, data, parentId)))
      .then(() => {
        transitionRequest(dispatch, url, REQUEST_STATES.SUCCESS)
        if (toast) {
          toast({
            text: toast,
            duration: DEFAULT_TOAST_DURATION,
            destination: 'https://github.com/apvarun/toastify-js',
            close: true,
            gravity: 'top', // `top` or `bottom`
            position: 'left', // `left`, `center` or `right`
            stopOnFocus: true, // Prevents dismissing of toast on hover
            style: {
              background: 'linear-gradient(to right, #00b09b, #96c93d)'
            },
            onClick: function () {} // Callback after click
          }).showToast()
        }
      })
      .then(() => resp)
      .catch(error => {
        const status = get(error, 'response.status')
        if (status === 401) {
          setAuthToken('')
          if (window.location.pathname !== '/signin') {
            window.location.replace('/signin?session=invalid')
          }
        }
        const errorResponse = get(error, 'response')
        transitionRequest(dispatch, url, REQUEST_STATES.FAILURE)
        if (toast) {
          toast()
        }
        return errorResponse
      })
  }
}

export { request, transitionRequest }
