import React from 'react'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { includes, map } from 'lodash'
import { useBoolean } from 'utils/state'

import { useOrganizationDashboards } from 'modules/dashboards/api'

import { MdOutlineAnalytics } from 'react-icons/md'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

import DivButton from 'components/div_button'

import { ButtonDefaults, ButtonProps } from '../constants'
import ExpandableButtonRow from './row'
import AddDashboardButton from './add'

import '../styles.scss'

const DashboardButton = ({ className, organizationId }) => {
  const [expanded, show, close] = useBoolean(true)
  const location = useLocation()
  const { pathname } = location
  const selected = includes(pathname, 'dashboards')

  const dashboards = useOrganizationDashboards({ parentId: organizationId })
  const navigate = useNavigate()

  const handleClick = () => {
    expanded ? close() : show()
  }

  const handleDashboardNavigate = id => () => {
    navigate(`/dashboards/${id}`)
  }

  return (
    <div className="expandable">
      <DivButton
        className={classNames('nav-button', className, { selected })}
        onClick={handleClick}
      >
        <div className="title">
          <MdOutlineAnalytics className="icon" size="1em" />
          Dashboards
        </div>
        {expanded ? (
          <FaChevronUp className="expand-icon" size="1em" />
        ) : (
          <FaChevronDown className="expand-icon" size="1em" />
        )}
      </DivButton>
      {expanded && (
        <>
          {map(dashboards, db => {
            const rowSelected = includes(pathname, db.id)
            return (
              <ExpandableButtonRow
                key={db.id}
                className={className}
                id={db.id}
                name={db.name}
                onClick={handleDashboardNavigate}
                selected={rowSelected}
              />
            )
          })}
          <AddDashboardButton
            className={className}
            organizationId={organizationId}
          />
        </>
      )}
    </div>
  )
}

DashboardButton.propTypes = {
  ...ButtonProps,
  organizationId: PropTypes.string
}
DashboardButton.defaultProps = { ...ButtonDefaults, organizationId: undefined }

export default DashboardButton
