import { generateApi } from 'utils/api'

const {
  selectMetric,
  selectMetrics,
  useMetric,
  useMetrics,
  useResourceMetrics
} = generateApi('metric', ['resource'])

export {
  selectMetric,
  selectMetrics,
  useMetric,
  useMetrics,
  useResourceMetrics
}
