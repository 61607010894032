import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import classNames from 'classnames'

import './styles.scss'

const Button = ({
  type,
  children,
  className,
  disabled,
  onClick,
  variant,
  size,
  ...props
}) => {
  return (
    <button
      type={type}
      className={classNames('button', className, variant, size, { disabled })}
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  )
}

Button.propTypes = {
  button: PropTypes.oneOf(['button', 'submit']),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  variant: PropTypes.oneOf([
    'primary',
    'primary-light',
    'secondary',
    'danger',
    'accent',
    'grey',
    'primary-outline',
    'secondary-outline',
    'danger-outline',
    'accent-outline',
    'grey-outline'
  ]),
  size: PropTypes.oneOf(['standard', 'thin'])
}

Button.defaultProps = {
  type: 'button',
  className: '',
  disabled: false,
  onClick: noop,
  variant: 'primary',
  size: 'standard'
}

export default Button
