import React, { useState } from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { authenticateShopify } from 'api/integrations'
import { isEmpty } from 'lodash'

import TextField from 'components/input/text'
import Button from 'components/button'
import Modal from 'components/modal'

import './styles.scss'

const AddShopModal = ({ open, onClose }) => {
  const dispatch = useDispatch()
  const [shopName, setShopName] = useState('')
  const handleChange = value => {
    setShopName(value)
  }

  const handleSubmit = () => {
    dispatch(authenticateShopify({ shopName }))
  }

  const canSubmit = isEmpty(shopName)
  return (
    <Modal open={open} onClose={onClose} className="add-shopify-modal">
      <div className="modal-content">
        <div className="title">Connect your Shopify Shop</div>
        <div className="shopname">
          <TextField
            label="To get started, enter your store name"
            value={shopName}
            onChange={handleChange}
          />
          .myshopify.com
        </div>
        <Button className="submit" onClick={handleSubmit} disabled={canSubmit}>
          Authenticate Store
        </Button>
      </div>
    </Modal>
  )
}

AddShopModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired
}

export default AddShopModal
