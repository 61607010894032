import React from 'react'

const Forbidden = () => {
  return (
    <div>
      <div>Only Lumberjacks are allowed here!</div>
    </div>
  )
}

export default Forbidden
