import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate, Link } from 'react-router-dom'
import { size } from 'lodash'

import { resetPassword } from 'api/auth'
import { useQueryParams } from 'utils/query'
import Button from 'components/button'
import TextField from 'components/input/text'
import Card from 'components/card'

import './styles.scss'

const ResetPassword = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { token } = useQueryParams()

  const [password, setPassword] = useState()
  const [passwordConfirm, setPasswordConfirm] = useState()

  const handleSubmit = () => {
    if (size(password) > 8 && password === passwordConfirm) {
      dispatch(
        resetPassword({
          token,
          password
        })
      ).then(resp => {
        if (resp.status === 200) {
          navigate('/signin')
        }
      })
    }
  }

  return (
    <Card className="auth-card">
      <div className="title">Reset Password</div>
      <div className="auth-content">
        <div className="auth-input">
          <TextField
            required
            id="password"
            className="auth-input"
            value={password}
            onChange={setPassword}
            label="Password"
            type="password"
            autoComplete="password"
          />
        </div>
        <div className="auth-input">
          <TextField
            required
            id="confrimPassword"
            className="auth-input"
            value={passwordConfirm}
            onChange={setPasswordConfirm}
            label="Confirm Password"
            type="password"
            autoComplete="password"
          />
        </div>
        <div className="auth-buttons">
          <Button
            className="wide-button"
            variant="primary"
            type="submit"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      <div className="auth-footer">
        <Link to="/forgot">
          <Button variant="grey-outline" className="auth-button">
            Forgot Password
          </Button>
        </Link>
        <Link to="/signin">
          <Button variant="primary-light" className="auth-button">
            Sign In
          </Button>
        </Link>
      </div>
    </Card>
  )
}

export default ResetPassword
