import React from 'react'
import { useOrganizations } from '../api'

import OrganizationListRow from './row'

import './styles.scss'
import CreateOrganizationButton from './create'

const ListOrganizations = () => {
  const organizations = useOrganizations()
  return (
    <div className="organization-list">
      {organizations.map(org => (
        <OrganizationListRow organizationId={org.id} />
      ))}
      <CreateOrganizationButton />
    </div>
  )
}

export default ListOrganizations
