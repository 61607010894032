import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { HiPencilAlt } from 'react-icons/hi'

import './styles.scss'

const Editable = ({ value, setValue, className, disabled }) => {
  const [editingValue, setEditingValue] = useState(value)
  const onChange = event => {
    setEditingValue(event.target.value)
  }
  const cssClass = classNames('editable', className)

  const onKeyDown = event => {
    if (event.keyCode === 13 || event.keyCode === 27) {
      event.target.blur()
    }
  }

  const onBlur = event => {
    if (event.target.value.trim() === '') {
      setEditingValue(value)
    } else if (value !== event.target.value) {
      setValue(event.target.value)
    }
  }

  useEffect(() => {
    setEditingValue(value)
  }, [value])

  return (
    <div className={cssClass}>
      <input
        type="text"
        value={editingValue}
        onChange={onChange}
        onKeyDown={onKeyDown}
        onBlur={onBlur}
        disabled={disabled}
      />
      <HiPencilAlt className="icon" />
    </div>
  )
}

Editable.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  setValue: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool
}

Editable.defaultProps = {
  className: undefined,
  disabled: false
}

export default Editable
