import ListJobs from 'modules/jobs/components/list'
import CreateScheduleJob from 'modules/jobs/components/start'
import React from 'react'

const Admin = () => {
  return (
    <div className="admin-panel">
      <CreateScheduleJob />
      <ListJobs />
    </div>
  )
}

export default Admin
