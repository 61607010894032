import React, { useContext, useState } from 'react'
import { get } from 'lodash'

import { AuthContext } from 'app/auth'
import { Link, useNavigate } from 'react-router-dom'
import Button from 'components/button'
import Card from 'components/card'
import TextField from 'components/input/text'

import './styles.scss'

const SignIn = () => {
  const navigate = useNavigate()
  const { handleLogin } = useContext(AuthContext)

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [error, setError] = useState()

  const handleSubmit = async event => {
    event.preventDefault()
    handleLogin({ email, password }).then(resp => {
      const status = get(resp, 'status')
      if (status !== 201) {
        setError('Unable to find that user and password!')
      } else {
        setError()
        navigate('/')
      }
    })
  }

  return (
    <Card className="auth-card">
      <div className="title">Sign In!</div>
      {error && <div className="auth-message error">{error}</div>}
      <form className="auth-content" onSubmit={handleSubmit}>
        <div className="auth-input">
          <TextField
            required
            id="email"
            value={email}
            onChange={setEmail}
            label="Email Address"
            name="email"
            type="text"
            autoComplete="email"
            error={!!error}
          />
        </div>
        <div className="auth-input">
          <TextField
            required
            id="password"
            value={password}
            onChange={setPassword}
            label="Password"
            name="password"
            type="password"
            autoComplete="password"
            error={!!error}
          />
        </div>
        <div className="auth-buttons">
          <Button className="wide-button" type="submit" variant="primary">
            Sign In
          </Button>
        </div>
      </form>
      <div className="auth-footer">
        <Link to="/forgot">
          <Button className="wide-button" variant="grey-outline">
            Forgot Password
          </Button>
        </Link>
      </div>
    </Card>
  )
}

export default SignIn
