const API_URL = process.env.REACT_APP_API_URL
const TTL = 1000
const TIMEOUT = 1000

const REQUEST_STATES = {
  BEGIN: 'BEGIN',
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE'
}

const ACTIONS = {
  DESTROY: 'API/DESTROY',
  RECEIVE: 'API/RECEIVE',
  REORDER: 'API/REORDER',
  STATE_TRANSITION: 'API/STATE_TRANSITION'
}

const DEFAULT_TOAST_DURATION = 1000

export {
  ACTIONS,
  API_URL,
  REQUEST_STATES,
  TIMEOUT,
  TTL,
  DEFAULT_TOAST_DURATION
}
