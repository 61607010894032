import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { forgotPassword } from 'api/auth'

import { Link } from 'react-router-dom'
import Button from 'components/button'
import TextField from 'components/input/text'
import Card from 'components/card'

import './styles.scss'

const ForgotPassword = () => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [checkEmail, setCheckEmail] = useState(false)

  const handleSubmit = async () => {
    dispatch(forgotPassword({ email })).then(resp => {
      if (resp.status === 200) {
        setCheckEmail(true)
      }
    })
  }

  if (checkEmail) {
    return (
      <Card className="auth-card">
        <div className="title">Check your email!</div>
        <div className="auth-content">
          <div>
            An email containing a link to reset your password was sent to{' '}
            {email}
          </div>
        </div>
      </Card>
    )
  }

  return (
    <Card className="auth-card">
      <div className="title">Don't Remember Your Password?</div>
      <div className="auth-content">
        <div className="auth-input">
          <TextField
            required
            id="email"
            value={email}
            onChange={setEmail}
            label="Email Address"
            name="email"
            type="email"
            autoComplete="email"
          />
        </div>
        <div className="auth-buttons">
          <Button
            className="wide-button"
            type="submit"
            variant="primary"
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
      <div className="auth-footer">
        <Link to="/signup">
          <Button variant="primary-light">Sign Up</Button>
        </Link>
        <Link to="/signin">
          <Button variant="primary-light">Sign In</Button>
        </Link>
      </div>
    </Card>
  )
}

export default ForgotPassword
