import React from 'react'
import { useDispatch } from 'react-redux'
import Button from 'components/button'

import { createOrganization } from '../api'

import './styles.scss'

const CreateOrganizationButton = () => {
  const dispatch = useDispatch()
  const name = 'My New Organization'

  const handleSubmit = () => {
    dispatch(createOrganization({ body: { name } })).then(() => {
      setName()
      onClose()
    })
  }

  return <Button onClick={handleSubmit}>Add Organization</Button>
}

CreateOrganizationButton.propTypes = {}

export default CreateOrganizationButton
