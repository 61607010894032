import { get, indexOf, map } from 'lodash'
import { arrayMove } from '@dnd-kit/sortable'

const moveImmutable = ({ items, overId, activeId, orderKey }) => {
  const originalOrder = map(items, r => r.id)
  const overIndex = indexOf(originalOrder, overId)
  const activeIndex = indexOf(originalOrder, activeId)
  const newOrder = arrayMove(originalOrder, activeIndex, overIndex)
  const updatedItems = map(items, item => {
    const id = get(item, 'id')
    return { ...item, [orderKey]: indexOf(newOrder, id) }
  })
  return updatedItems
}

export { moveImmutable }
