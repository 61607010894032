import React from 'react'
import PropTypes from 'prop-types'
import { isEmpty, values } from 'lodash'
import { useNavigate, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { selectAccount } from 'modules/accounts/api'
import { useAccountAccountMetrics } from 'modules/account_metrics/api'

import Loading from 'components/loading'
import Button from 'components/button'
import Card from 'components/card'
import AccountHealth from '../health'

import './styles.scss'

const ManageAccount = () => {
  const navigate = useNavigate()
  const { accountId } = useParams()
  const account = useSelector(state => selectAccount(state, accountId))
  const { healthy, name } = account

  const accountMetrics = values(
    useAccountAccountMetrics({
      parentId: accountId
    })
  )

  if (isEmpty(accountMetrics)) {
    return <Loading />
  }

  const handleNavigate = amId => () => {
    navigate(`/account_metrics/${amId}`)
  }

  return (
    <Card>
      <div className="header">
        <div>{name}</div>
        <AccountHealth healthy={healthy} />
      </div>
      <div className="container">
        <div className="title">Account Metrics</div>
        {accountMetrics.map(am => (
          <div className="metric-row">
            <div className="name">{am.metricName}</div>
            {am.lastFetchedAt && (
              <div className="last-fetched-at">{am.lastFetchedAt}</div>
            )}
            <Button onClick={handleNavigate(am.id)}>Manage</Button>
          </div>
        ))}
      </div>
    </Card>
  )
}

ManageAccount.propTypes = {
  accountId: PropTypes.string.isRequired
}

export default ManageAccount
