import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { format, subMonths } from 'date-fns'
import { get } from 'lodash'
import { setRangeStart, setRangeEnd, setCurrentOrganizationId } from './actions'
import {
  selectRangeStart,
  selectRangeEnd,
  selectCurrentOrganizationId
} from './selectors'

const DATE_FORMAT = 'yyyy-MM-dd'

const useCurrentRange = () => {
  const dispatch = useDispatch()
  const rangeStart = useSelector(selectRangeStart)
  const rangeEnd = useSelector(selectRangeEnd)

  useEffect(() => {
    if (!rangeStart && !rangeEnd) {
      const now = new Date()
      const inititalEndDate = format(now, DATE_FORMAT)
      const initialStartDate = format(subMonths(now, 1), DATE_FORMAT)
      dispatch(setRangeStart(initialStartDate))
      dispatch(setRangeEnd(inititalEndDate))
    }
  })
  return [rangeStart, rangeEnd]
}

const useCurrentOrganizationId = organizations => {
  const dispatch = useDispatch()
  const currentOrganizationId = useSelector(selectCurrentOrganizationId)

  useEffect(() => {
    if (!currentOrganizationId && organizations) {
      const firstOrganizationId = get(organizations, '0.id')
      if (firstOrganizationId) {
        dispatch(setCurrentOrganizationId(firstOrganizationId))
      }
    }
  })

  return currentOrganizationId
}

export { useCurrentRange, useCurrentOrganizationId }
