import React, { createContext, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { get } from 'lodash'
import { login } from 'api/auth'
import { setAuthToken, getAuthToken } from 'utils/auth'
import { useDispatch } from 'react-redux'

const AuthContext = createContext({
  isAuthenticated: false,
  handleLogin: undefined,
  handleLogout: undefined
})

const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const token = getAuthToken()

  const [isAuthenticated, setIsAuthenticated] = useState(token)

  const handleLogin = body => {
    return dispatch(login(body)).then(response => {
      const token = get(response, 'data.token')
      if (token) {
        setAuthToken(token)
        setIsAuthenticated(true)
      }
      return response
    })
  }

  const handleLogout = () => {
    setAuthToken('')
    setIsAuthenticated(false)
    navigate('/signin')
  }

  const authContext = {
    isAuthenticated,
    handleLogin,
    handleLogout
  }

  useEffect(() => {
    if (token) {
      setIsAuthenticated(true)
    } else {
      setIsAuthenticated(false)
    }
  }, [token, isAuthenticated, setIsAuthenticated])

  return (
    <AuthContext.Provider value={authContext}>{children}</AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired
}

export { AuthProvider, AuthContext }
