import React from 'react'
import PropTypes from 'prop-types'

import { useDispatch } from 'react-redux'
import { format, subDays, subMonths } from 'date-fns'
import classNames from 'classnames'

import { setRangeEnd, setRangeStart } from '../actions'

import './styles.scss'

const DATE_FORMAT = 'yyyy-MM-dd'
const DateBubble = ({ label, unit, increment, value, setValue }) => {
  const dispatch = useDispatch()
  const end = new Date()
  const start =
    unit === 'month' ? subMonths(end, increment) : subDays(end, increment)

  const selected = label === value
  const cssClasses = classNames('date-bubble', { selected })
  const handleClick = () => {
    setValue(label)
    dispatch(setRangeEnd(format(end, DATE_FORMAT)))
    dispatch(setRangeStart(format(start, DATE_FORMAT)))
  }
  return (
    <div onClick={handleClick} className={cssClasses}>
      {label}
    </div>
  )
}

DateBubble.propTypes = {
  label: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  increment: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired
}

export default DateBubble
