import { createSelector } from '@reduxjs/toolkit'
import { find, get, keyBy, map, merge, orderBy, values } from 'lodash'
import { generateApi } from 'utils/api'
import { createPropGetter } from 'utils/api/selectors'
import { filterCollection } from 'utils/api/filter'

const {
  selectEntries,
  selectAccountMetricEntries,
  useAccountMetricEntries,
  useDashboardItemEntries,
  updateEntry
} = generateApi('entry', ['accountMetric', 'dashboardItem'])

const selectAccountMetricEntry = createSelector(
  selectEntries,
  createPropGetter('accountMetricId'),
  createPropGetter('entryId'),
  (entries, accountMetricId, entryId) => {
    const amEntries = get(entries, accountMetricId)
    return find(amEntries, ['id', entryId])
  }
)

const selectAccountMetricsEntries = createSelector(
  selectEntries,
  createPropGetter('accountMetricIds'),
  createPropGetter('start'),
  createPropGetter('end'),
  (entries, accountMetricIds, start, end) => {
    let outputEntries = {}
    accountMetricIds.forEach(accountMetricId => {
      let amEntries = filterCollection(get(entries, accountMetricId, []), {
        start,
        end
      })
      amEntries = map(amEntries, entry => {
        return { [accountMetricId]: entry.value, ...entry }
      })
      const amEntryD = keyBy(amEntries, 'endTime')
      outputEntries = merge(outputEntries, amEntryD)
    })
    return orderBy(values(outputEntries), 'endTime')
  }
)

export {
  selectAccountMetricEntry,
  selectEntries,
  selectAccountMetricEntries,
  selectAccountMetricsEntries,
  useAccountMetricEntries,
  useDashboardItemEntries,
  updateEntry
}
