import React from 'react'
import { AppProvider } from '@shopify/polaris'
import { Provider } from '@shopify/app-bridge-react'
import { includes } from 'lodash'

import './style.scss'

const SHOPIFY_DOMAINS = ['admin.shopify.com', 'myshopify.com']

const isShopifyDomain = domain => {
  return includes(SHOPIFY_DOMAINS, domain)
}

const ShopifyProvider = ({ children }) => {
  const isShopify = isShopifyDomain(location.hostname)
  const host = new URLSearchParams(location.search).get('host')

  const config = {
    apiKey: process.env.REACT_APP_SHOPIFY_API_KEY,
    host,
    forceRedirect: true
  }

  if (!isShopify) {
    return children
  }

  return (
    <AppProvider>
      <Provider config={config}>{children}</Provider>
    </AppProvider>
  )
}

export default ShopifyProvider
