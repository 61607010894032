import React from 'react'
import PropTypes from 'prop-types'
import Select from 'react-select'
import classNames from 'classnames'
import { find } from 'lodash'

import './styles.scss'

const SelectField = ({
  className,
  disabled,
  onChange,
  options,
  placeholder,
  value
}) => {
  const cssClass = classNames('select-field', className)
  const selected = find(options, { value }) ?? false

  return (
    <Select
      className={cssClass}
      isDisabled={disabled}
      options={options}
      value={selected}
      onChange={onChange}
      placeholder={placeholder}
    />
  )
}

SelectField.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  placeholder: PropTypes.string,
  value: PropTypes.string
}

SelectField.defaultProps = {
  className: undefined,
  disabled: false,
  options: [],
  placeholder: undefined,
  value: undefined
}

export default SelectField
