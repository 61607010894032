import React from 'react'
import PropTypes from 'prop-types'
import Card from 'components/card'

import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { map, sumBy } from 'lodash'
import { useSelector } from 'react-redux'
import { selectAccountMetric } from 'modules/account_metrics/api'

import './styles.scss'

const ListRow = ({ accountMetricId, value }) => {
  const accountMetric = useSelector(state =>
    selectAccountMetric(state, accountMetricId)
  )
  const { metricName } = accountMetric
  return (
    <div className="tooltip-row">
      <div className="title">{metricName}</div>
      {value}
    </div>
  )
}

ListRow.propTypes = {
  accountMetricId: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
}

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    const displayDate = format(parseISO(label), 'M/d/yyyy')
    const values = map(payload, p => {
      return <ListRow key={p.name} accountMetricId={p.name} value={p.value} />
    })
    const total = sumBy(payload, 'value')

    return (
      <Card className="tooltip">
        <div className="header">
          <div>{displayDate}</div>
          <div>{total}</div>
        </div>

        {values}
      </Card>
    )
  }

  return null
}

CustomTooltip.propTypes = {
  active: PropTypes.bool.isRequired,
  payload: PropTypes.shape({}),
  label: PropTypes.string
}

CustomTooltip.defaultProps = {
  payload: {},
  label: undefined
}

export default CustomTooltip
