import React from 'react'
import PropTypes from 'prop-types'
import { uniq } from 'lodash'

import VendorIcon from '../vendor_icon'

import './styles.scss'

const VendorIcons = ({ vendorIds }) => {
  const icons = uniq(vendorIds)
  return (
    <div className="vendor-icons">
      {icons.map(vendorId => (
        <VendorIcon vendorId={vendorId} />
      ))}
    </div>
  )
}

VendorIcons.propTypes = {
  vendorIds: PropTypes.arrayOf(PropTypes.string).isRequired
}

export default VendorIcons
