// Primary Colors
const COLORS = {
  // Primary Colors
  green_main: '#349D52',
  green_dark: '#07513c',
  green_light: '#3b9078',

  // Accent
  secondary_main: '#31adff',
  secondary_dark: '#2279b2',
  secondary_light: '#5abdff',

  red_main: '#931c1c',
  red_dark: '#551313',
  red_light: '#f14b4b',

  // Accent Colors
  accent_main: '#69bfab',
  accent_dark: '#3b1c00',
  accent_light: '#9ef5e1',

  // Neutral Colors
  dark_1: '#1d1d35',
  grey_1: '#c5cecb',
  grey_2: '#a3b2b0',
  grey_3: '#839798',
  neutral_1: '#f7fffd',
  neutral_2: '#dae9e5'
}

export { COLORS }
