import React from 'react'
import PropTypes from 'prop-types'
import { get } from 'lodash'
import classNames from 'classnames'

import './styles.scss'

const TextField = ({
  autoComplete,
  id,
  label,
  name,
  onChange,
  placeholder,
  type,
  value,
  disabled,
  error
}) => {
  const cssClass = classNames(['input-text', name], { error })
  const handleChange = e => {
    const value = get(e, 'target.value')
    onChange(value)
  }

  return (
    <label>
      {label && <div className="label-text">{label}</div>}
      <input
        id={id}
        className={cssClass}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        autoComplete={autoComplete}
        disabled={disabled}
      />
    </label>
  )
}

TextField.propTypes = {
  autoComplete: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  type: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  error: PropTypes.bool
}

TextField.defaultProps = {
  autoComplete: '',
  id: null,
  className: '',
  name: '',
  label: null,
  placeholder: undefined,
  type: 'text',
  value: undefined,
  disabled: false,
  error: false
}

export default TextField
