import React, { useEffect, useState } from 'react'
import { useQueryParams } from 'utils/query'

import { Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { confirmShopify } from 'api/integrations'

const ConfirmShop = () => {
  const dispatch = useDispatch()
  const [isSuccess, setIsSuccess] = useState(false)
  const params = useQueryParams()
  const confirmShop = () => {
    dispatch(confirmShopify({ ...params })).then(() => {
      setIsSuccess(true)
    })
  }

  useEffect(() => {
    confirmShop()
  }, [])

  if (isSuccess) {
    return <Navigate to="/settings/connections" />
  }

  return <div>Failed to link shopify account.</div>
}

export default ConfirmShop
