import React from 'react'

const NotFound = () => {
  return (
    <div>
      <div>Page Not Found</div>
      <div>So you are looking for a snipe?</div>
      <div>Don't you know they don't exist</div>
      <a href="https://en.wikipedia.org/wiki/Snipe_hunt">
        What is a Snipe Hunt?
      </a>
    </div>
  )
}

export default NotFound
