import { get, isArray, keyBy, merge, omit } from 'lodash'
import { plural } from 'pluralize'
import { ACTIONS } from './constants'
import { moveImmutable } from 'utils/move'

const extractData = data => {
  const objects = isArray(data) ? data : [data]
  return keyBy(objects, 'id')
}

const receiveApi = (state, action) => {
  const resource = get(action, 'resource')
  const data = get(action, 'data', {})
  const method = get(action, 'method')
  const parentId = get(action, 'parentId')

  if (method === 'DELETE') {
    const id = get(action, 'data.id')
    return omit(state, `${plural(resource)}.${id}`)
  }
  let resourceState = {}
  if (resource === 'entry') {
    // Account Metric Entries
    if (isArray(data)) {
      resourceState = {
        [plural(resource)]: {
          [parentId]: extractData(data)
        }
      }
    }
    //  Dashboard Item Entries
    else {
      const accountMetricEntries = {}
      Object.entries(data).forEach(([key, entries]) => {
        accountMetricEntries[key] = extractData(entries)
      })
      resourceState = {
        [plural(resource)]: accountMetricEntries
      }
    }
  } else {
    resourceState = { [plural(resource)]: extractData(data) }
  }

  return merge({}, state, resourceState)
}

const reorderEntities = (state, action) => {
  const { resource, orderKey, activeId, overId, items } = action
  const updatedItems = moveImmutable({ items, overId, activeId, orderKey })
  return merge({}, state, { [plural(resource)]: extractData(updatedItems) })
}

const createResourceReducer = () => {
  return (state = {}, action) => {
    const type = get(action, 'type')
    const resource = get(action, 'resource')
    if (!resource) {
      return state
    }
    switch (type) {
      case ACTIONS.RECEIVE:
        return receiveApi(state, action)
      case ACTIONS.REORDER:
        return reorderEntities(state, action)
      default:
        return state
    }
  }
}

const requestReducer = (state = {}, action) => {
  const type = get(action, 'type')
  const url = get(action, 'url')
  const requestState = get(action, 'requestState')

  switch (type) {
    case ACTIONS.STATE_TRANSITION:
      return merge({}, state, {
        [url]: { state: requestState, time: Date.now() }
      })
    default:
      return state
  }
}

export { createResourceReducer, requestReducer }
