import React from 'react'
import { render } from 'react-dom'
import App from 'app'
import reportWebVitals from './reportWebVitals'
import './styles/index.scss'

function startApp() {
  render(<App />, document.getElementById('root'))
}

startApp()
reportWebVitals()
