import { createSelector } from '@reduxjs/toolkit'
import { get, pick, values } from 'lodash'
import { generateApi } from 'utils/api'
import { createPropGetter } from 'utils/api/selectors'

const {
  selectAccountMetric,
  selectAccountMetrics,
  updateAccountMetric,
  useAccountMetric,
  useOrganizationAccountMetrics,
  useAccountAccountMetrics
} = generateApi('accountMetric', ['account', 'organization'])

const selectAccountMetricColors = createSelector(
  selectAccountMetrics,
  createPropGetter('accountMetricIds'),
  (accounMetrics, ids) => {
    const colors = {}
    const filteredAccountMetrics = values(pick(accounMetrics, ids))
    filteredAccountMetrics.forEach(am => {
      colors[am.id] = get(am, 'color', '#1d4299')
    })
    return colors
  }
)

export {
  selectAccountMetric,
  selectAccountMetrics,
  selectAccountMetricColors,
  updateAccountMetric,
  useAccountMetric,
  useOrganizationAccountMetrics,
  useAccountAccountMetrics
}
