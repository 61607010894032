import React from 'react'
import { useParams } from 'react-router-dom'
import { map, capitalize } from 'lodash'

import StripeManage from 'modules/stripe/components/manage'
import ConnectionsPage from 'modules/accounts/components/connections'

import SettingsTabs from './tabs'
import MyProfile from '../profile'

import './styles.scss'

const SettingsTab = ({ children, name, tab }) => {
  if (tab === name) {
    return <div className="settings-tab">{children}</div>
  }
  return null
}

const Settings = () => {
  const params = useParams()
  const { tab: currentTab } = params

  const tabs = [
    {
      name: 'profile',
      component: <MyProfile />
    },
    {
      name: 'connections',
      component: <ConnectionsPage />
    },
    {
      name: 'subscription',
      component: <StripeManage />
    }
  ]

  return (
    <div className="settings">
      <div className="title">
        Account Settings / <span>{capitalize(currentTab)}</span>
      </div>
      <SettingsTabs />
      {map(tabs, tab => (
        <SettingsTab
          key={tab.name}
          name={tab.name}
          tab={currentTab}
          title={tab.title}
        >
          {tab.component}
        </SettingsTab>
      ))}
    </div>
  )
}

export default Settings
