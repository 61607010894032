import { createSelector } from '@reduxjs/toolkit'
import { orderBy } from 'lodash'
import { generateApi } from 'utils/api'
import { request } from 'utils/api/request'
import { ACTIONS } from 'utils/api/constants'

const {
  createDashboardDashboardItem,
  updateDashboardItem,
  destroyDashboardItem,
  useDashboardItem,
  useDashboardDashboardItems,
  selectDashboardDashboardItems,
  selectDashboardItem
} = generateApi('dashboardItem', ['dashboard'])

const selectOrderedDashboardItems = createSelector(
  selectDashboardDashboardItems,
  items => orderBy(items, ['x', 'createdAt'])
)

const reorderDashboardItems = ({ activeId, overId, items }) => {
  return {
    type: ACTIONS.REORDER,
    resource: 'dashboardItems',
    orderKey: 'x',
    items,
    activeId,
    overId
  }
}

const moveDashboardItems = ({ body, dashboardId }) => {
  return request({
    resource: 'dashboard',
    method: 'POST',
    path: `/dashboards/${dashboardId}/move`,
    body,
    parentId: dashboardId
  })
}

export {
  createDashboardDashboardItem,
  updateDashboardItem,
  destroyDashboardItem,
  useDashboardDashboardItems,
  useDashboardItem,
  selectOrderedDashboardItems,
  selectDashboardItem,
  reorderDashboardItems,
  moveDashboardItems
}
