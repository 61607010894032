import React, { useContext, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { get } from 'lodash'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from 'app/auth'
import { dateFormatter } from 'utils/datetime'
import { bigNumberFormatter } from 'utils/numbers'

import { selectRangeEnd, selectRangeStart } from 'modules/layout/selectors'
import { useAccountMetric } from 'modules/account_metrics/api'
import { selectAccountMetricEntries } from 'modules/entries/apis'
import { selectAccount } from 'modules/accounts/api'

import { MdSettings } from 'react-icons/md'
import DivButton from 'components/div_button'
import VendorIcon from 'modules/vendors/components/vendor_icon'
import Chart from 'components/dashboard/chart'

import './styles.scss'

const AccountMetricChart = ({ accountMetricId }) => {
  const navigate = useNavigate()
  const start = useSelector(selectRangeStart)
  const end = useSelector(selectRangeEnd)

  const { isAdmin } = useContext(AuthContext)

  const accountMetric = useAccountMetric({ objectId: accountMetricId })

  const { accountId, targetAttributes, metricName, vendorId, resourceName } =
    accountMetric
  const target = get(targetAttributes, 'value')
  const account = useSelector(state => selectAccount(state, accountId))
  const { name: accountName } = account

  const handleSettingsClick = () => {
    navigate(`/account_metrics/${accountMetricId}`)
  }

  const entries = useSelector(state =>
    selectAccountMetricEntries(state, accountMetricId, { start, end })
  )

  const data = useMemo(() => {
    if (target) {
      return entries.map(e => {
        e['target'] = Number(target)
        return e
      })
    }
    return entries
  })

  return (
    <div className="chart-content">
      <div className="header">
        <div className="title">
          <div className="resource">{`${accountName}`}</div>
          {metricName}
          {isAdmin && ` (${accountMetricId})`}
        </div>
        <DivButton className="settings-icon" onClick={handleSettingsClick}>
          <MdSettings size="1em" />
        </DivButton>
      </div>
      <div className="item-container">
        <Chart
          keys={['value', 'previous', 'movingAverage', 'target']}
          data={data}
          xAxisKey={'endTime'}
          xAxisFormatter={dateFormatter}
          yAxisFormatter={bigNumberFormatter}
        />
      </div>
      <div className="item-footer">
        <div className="resource">{`${resourceName}`}</div>

        {vendorId && (
          <div className="icon">
            <VendorIcon vendorId={vendorId} />
          </div>
        )}
      </div>
    </div>
  )
}

AccountMetricChart.propTypes = {
  accountMetricId: PropTypes.string.isRequired
}

AccountMetricChart.defaultProps = {}

export default AccountMetricChart
