import { get } from 'lodash'

const SET_RANGE_START = 'NAVIGATION/SET_RANGE_START'
const SET_RANGE_END = 'NAVIGATION/SET_RANGE_END'
const SET_CURRENT_ORG_ID = 'NAVIGATION/SET_CURRENT_ORG_ID'

const navigationReducer = (state = {}, action) => {
  const type = get(action, 'type')
  switch (type) {
    case SET_RANGE_START:
      return { ...state, rangeStart: action.rangeStart }
    case SET_RANGE_END:
      return { ...state, rangeEnd: action.rangeEnd }
    case SET_CURRENT_ORG_ID:
      return { ...state, currentOrganizationId: action.organizationId }
    default:
      return state
  }
}

export { navigationReducer, SET_RANGE_START, SET_RANGE_END, SET_CURRENT_ORG_ID }
