import { generateApi } from 'utils/api'

const {
  createOrganization,
  updateOrganization,
  selectOrganization,
  selectOrganizations,
  useOrganization,
  useOrganizations
} = generateApi('organization')

export {
  createOrganization,
  updateOrganization,
  selectOrganization,
  selectOrganizations,
  useOrganization,
  useOrganizations
}
