import React from 'react'
import PropTypes from 'prop-types'
import Editable from 'components/input/editable'
import { useDispatch, useSelector } from 'react-redux'

import { selectOrganization, updateOrganization } from '../api'

import './styles.scss'

const OrganizationListRow = ({ organizationId }) => {
  const dispatch = useDispatch()
  const organization = useSelector(state =>
    selectOrganization(state, organizationId)
  )
  const { name } = organization
  const updateOrganizationName = name => {
    dispatch(updateOrganization({ objectId: organizationId, body: { name } }))
  }
  return (
    <div className="row">
      <Editable value={name} setValue={updateOrganizationName} />
    </div>
  )
}

OrganizationListRow.propTypes = {
  organizationId: PropTypes.string.isRequired
}

export default OrganizationListRow
