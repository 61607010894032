import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { selectAccountMetricEntry } from 'modules/entries/apis'

import './styles.scss'

const EntryRow = ({ accountMetricId, entryId, globalTarget }) => {
  const entry = useSelector(state =>
    selectAccountMetricEntry(state, {
      accountMetricId,
      entryId
    })
  )

  const { endTime, value, targetValue } = entry

  return (
    <div className="entry-row">
      <div>{endTime}</div>
      <div className="values">
        <div className="value">
          <div className="label">Current:</div>
          <span>{value}</span>
        </div>
        <div className="value">
          <div className="label">Target:</div>
          <span>{targetValue || globalTarget}</span>
        </div>
      </div>
    </div>
  )
}

EntryRow.propTypes = {
  accountMetricId: PropTypes.string.isRequired,
  entryId: PropTypes.string.isRequired,
  globalTarget: PropTypes.number
}

EntryRow.defaultProps = {
  globalTarget: undefined
}

export default EntryRow
