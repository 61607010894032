import React from 'react'

import ListAccounts from '../list'
import ConnectAccounts from '../connect'

import './styles.scss'

const ConnectionsPage = () => {
  return (
    <div className="manage-accounts">
      <ConnectAccounts />
      <ListAccounts />
    </div>
  )
}

export default ConnectionsPage
