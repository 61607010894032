import { snakeCase } from 'lodash'
import pluralize from 'pluralize'
import { request } from './request'

const generateCrudActions = (resource, parentResource = undefined) => {
  const resourceName = pluralize(snakeCase(resource))
  const parentName = pluralize(snakeCase(parentResource))
  const basePath = `/${resourceName}`

  let create
  let fetchAll
  if (parentResource) {
    create = ({ body, parentId = undefined, options = {} }) => {
      return request({
        resource,
        method: 'POST',
        path: `/${parentName}/${parentId}/${resourceName}`,
        body,
        parentId,
        options
      })
    }
    fetchAll = ({ params, parentId = undefined, options = {} }) => {
      return request({
        resource,
        method: 'GET',
        path: `/${parentName}/${parentId}/${resourceName}`,
        parentId,
        params,
        options
      })
    }
  } else {
    create = ({ body, options = {} }) => {
      return request({
        resource,
        method: 'POST',
        path: basePath,
        body,
        options
      })
    }
    fetchAll = ({ params, options = {} }) => {
      return request({
        resource,
        method: 'GET',
        path: basePath,
        params,
        options
      })
    }
  }
  const destroy = ({ objectId, options = {} }) => {
    return request({
      resource,
      method: 'DELETE',
      path: `${basePath}/${objectId}`,
      options
    })
  }
  const fetch = ({ objectId, params, options = {} }) => {
    return request({
      resource,
      method: 'GET',
      path: `${basePath}/${objectId}`,
      params,
      options
    })
  }
  const update = ({ objectId, body, options = {} }) => {
    return request({
      resource,
      method: 'PUT',
      path: `${basePath}/${objectId}`,
      body,
      options
    })
  }

  return {
    create,
    destroy,
    fetch,
    fetchAll,
    update
  }
}

export { generateCrudActions }
