// import logger from 'redux-logger'
import { configureStore, combineReducers } from '@reduxjs/toolkit'
import { navigationReducer } from 'modules/layout/dux'
import { createResourceReducer, requestReducer } from 'utils/api/dux'

const store = configureStore({
  reducer: combineReducers({
    api: createResourceReducer(),
    requests: requestReducer,
    navigation: navigationReducer
  }),
  devTools: process.env.NODE_ENV !== 'prod'
  // middleware: getDefaultMiddleware => getDefaultMiddleware().concat(logger)
})

export default function getStore() {
  return store
}
