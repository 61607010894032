import React, { useContext } from 'react'
import PropTypes from 'prop-types'

import { Navigate } from 'react-router-dom'
import { isEmpty } from 'lodash'

import { AuthContext } from 'app/auth'
import { useCurrentUser } from 'modules/users/api'
import Loading from 'components/loading'
import SidePanel from 'modules/layout/navigation/side_panel'

import './styles.scss'

const AuthorizeLayout = ({ children, requireAdmin, requireSubscription }) => {
  const user = useCurrentUser()
  const { isActive, isAdmin } = user
  if (isEmpty(user)) {
    return <Loading />
  }

  if (requireSubscription && !isActive) {
    return (
      <Navigate
        to="/welcome?hidePanel=true"
        replace
        state={{ path: location.pathname }}
      />
    )
  }
  if (requireAdmin && !isAdmin) {
    return <Navigate to="/" replace state={{ path: location.pathname }} />
  }
  return (
    <div className="app-layout">
      <SidePanel isActive={isActive} />
      <div className="content">{children}</div>
    </div>
  )
}

AuthorizeLayout.propTypes = {
  children: PropTypes.node.isRequired,
  requireAdmin: PropTypes.bool,
  requireSubscription: PropTypes.bool
}

AuthorizeLayout.defaultProps = {
  requireAdmin: false,
  requireSubscription: false
}

const AuthLayout = ({ children, requireSubscription, requireAdmin }) => {
  const { isAuthenticated } = useContext(AuthContext)
  if (!isAuthenticated) {
    return <Navigate to="/signin" replace state={{ path: location.pathname }} />
  }

  return (
    <AuthorizeLayout
      requireSubscription={requireSubscription}
      requireAdmin={requireAdmin}
    >
      {children}
    </AuthorizeLayout>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node.isRequired,
  requireAdmin: PropTypes.bool,
  requireSubscription: PropTypes.bool
}

AuthLayout.defaultProps = {
  requireAdmin: false,
  requireSubscription: false
}

export default AuthLayout
