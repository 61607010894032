import { generateApi } from 'utils/api'
import { request } from 'utils/api/request'

const { selectJob, selectJobs, useJob, useJobs, destroyJob } =
  generateApi('job')

const createScheduleJob = body => {
  return request({ body, path: '/jobs/start', method: 'POST' })
}

export { selectJob, selectJobs, useJob, useJobs, destroyJob, createScheduleJob }
