import React from 'react'
import PropTypes from 'prop-types'
import { noop } from 'lodash'
import classNames from 'classnames'

import './styles.scss'

const DivButton = ({ children, className, disabled, onClick }) => {
  return (
    <div
      className={classNames('div-button', className, {
        disabled
      })}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </div>
  )
}

DivButton.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func
}

DivButton.defaultProps = {
  className: '',
  disabled: false,
  onClick: noop
}

export default DivButton
