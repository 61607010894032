import React from 'react'
import PropTypes from 'prop-types'

import { MdCheckCircle, MdError } from 'react-icons/md'
import { COLORS } from 'constants/colors'

const AccountHealth = ({ healthy, iconSize }) => {
  return (
    <div className="health">
      {healthy ? (
        <MdCheckCircle size={iconSize} color={COLORS.green_main} />
      ) : (
        <MdError size={iconSize} color={COLORS.red_main} />
      )}
    </div>
  )
}

AccountHealth.propTypes = {
  healthy: PropTypes.bool,
  iconSize: PropTypes.string
}

AccountHealth.defaultProps = {
  healthy: false,
  iconSize: '24px'
}

export default AccountHealth
