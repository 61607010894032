import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useBoolean } from 'utils/state'

import { selectDashboard } from 'modules/dashboards/api'
import { useDashboardDashboardItems } from 'modules/dashboard_items/api'

import { MdAddchart } from 'react-icons/md'
import CreateDashboardItemModal from 'modules/dashboard_items/components/create'
import Button from 'components/button'

import DashboardItems from './items'
import DashboardTitle from './title'

import './styles.scss'

const Dashboard = ({ dashboardId }) => {
  const [open, onOpen, onClose] = useBoolean(false)
  const dashboard = useSelector(state => selectDashboard(state, dashboardId))
  useDashboardDashboardItems({ parentId: dashboardId })
  const { name } = dashboard

  return (
    <div className="dashboard">
      <div className="dashboard-header">
        {name && <DashboardTitle name={name} dashboardId={dashboardId} />}
        <Button className="add-dashboard-item" onClick={onOpen}>
          <MdAddchart size="1.5em" />
        </Button>
      </div>

      {dashboardId && (
        <CreateDashboardItemModal
          dashboardId={dashboardId}
          open={open}
          onClose={onClose}
        />
      )}
      {dashboardId && <DashboardItems dashboardId={dashboardId} />}
    </div>
  )
}

Dashboard.propTypes = {
  dashboardId: PropTypes.string
}

Dashboard.defaultProps = {
  dashboardId: undefined
}

export default Dashboard
