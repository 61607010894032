import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { updateUser, useCurrentUser } from 'modules/users/api'

import Card from 'components/card'
import ListOrganizations from 'modules/organizations/components/list'
import TextField from 'components/input/text'

import './styles.scss'
import Button from 'components/button'

const MyProfile = () => {
  const dispatch = useDispatch()
  const user = useCurrentUser()
  const { id } = user
  const [body, setBody] = useState(user)

  const handleUpdate = field => value => {
    setBody({ ...body, [field]: value, isAdmin: true })
  }

  const handleSave = () => {
    dispatch(updateUser({ objectId: id, body }))
  }

  return (
    <div>
      <Card className="my-profile-card">
        <div>My Organizations</div>
        <ListOrganizations />
      </Card>
      <Card className="my-profile-card">
        <div>Profile</div>
        <div sm={6} className="auth-input">
          <TextField
            autoFocus
            required
            autoComplete="email"
            name="email"
            label="Email"
            value={body.email}
            onChange={handleUpdate('email')}
            disabled
          />
        </div>
        <div sm={6} className="auth-input">
          <TextField
            autoFocus
            required
            autoComplete="given-name"
            name="firstName"
            label="First Name"
            value={body.firstName}
            onChange={handleUpdate('firstName')}
          />
        </div>
        <div sm={6} className="auth-input">
          <TextField
            required
            label="Last Name"
            name="lastName"
            autoComplete="family-name"
            value={body.lastName}
            onChange={handleUpdate('lastName')}
          />
        </div>
        <Button onClick={handleSave}>Save</Button>
      </Card>
    </div>
  )
}

export default MyProfile
