import PropTypes from 'prop-types'

const ButtonProps = {
  className: PropTypes.string
}

const ButtonDefaults = {
  className: undefined
}

export { ButtonDefaults, ButtonProps }
