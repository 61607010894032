import { generateCrudActions } from './actions'
import { generateHooks } from './hooks'
import { generateSelectors } from './selectors'
import { upperCase } from './string'

const generateApi = (resource, parents = []) => {
  const actions = {}
  parents.forEach(parent => {
    const { create } = generateCrudActions(resource, parent)
    actions[`create${upperCase(parent)}${upperCase(resource)}`] = ({
      body,
      parentId,
      options
    }) => create({ body, parentId, options })
  })

  const { create, update, destroy } = generateCrudActions(resource)
  const selectors = generateSelectors(resource, parents)
  const hooks = generateHooks(resource, parents)
  return {
    ...selectors,
    ...hooks,
    ...actions,
    [`create${upperCase(resource)}`]: ({ body, options }) =>
      create({ body, options }),
    [`destroy${upperCase(resource)}`]: ({ objectId, options }) =>
      destroy({ objectId, options }),
    [`update${upperCase(resource)}`]: ({ objectId, body, options }) =>
      update({ objectId, body, options })
  }
}

export { generateApi }
