import React, { useCallback, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { createPortal } from 'react-dom'

import './styles.scss'

const Modal = ({ id, children, className, onClose, open, width }) => {
  const cssClasses = classNames('modal', className, { 'modal-open': open })
  const modalContainer = useRef()
  const backdrop = useRef()

  const closeModalOnBackdrop = useCallback(
    e => {
      if (backdrop.current.contains(e.target)) {
        onClose()
      }
    },
    [onClose]
  )

  useEffect(() => {
    const container = modalContainer.current
    if (open) {
      document.body.classList.add('modal-open')
      document.getElementById('modal-root').append(container)
    }
    if (!open) {
      document.getElementById('modal-root').prepend(container)
    }
    return () => {
      document.body.classList.remove('modal-open')
    }
  }, [open])

  useEffect(() => {
    document.addEventListener('mousedown', closeModalOnBackdrop)
    return () => {
      document.removeEventListener('mousedown', closeModalOnBackdrop)
    }
  }, [closeModalOnBackdrop])

  const content = (
    <div id={id} className={cssClasses} ref={modalContainer}>
      <div className="modal-backdrop" ref={backdrop} />
      <div className="modal-content" style={{ width: `${width}%` }}>
        {children}
      </div>
    </div>
  )

  return createPortal(content, document.querySelector('#modal-root'))
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  width: PropTypes.number
}

Modal.defaultProps = {
  className: '',
  width: 60
}

export default Modal
