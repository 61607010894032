import React from 'react'
import { useDispatch } from 'react-redux'
import { createStripeSession } from '../actions'

import Card from 'components/card'
import Button from 'components/button'

import { FaStripeS } from 'react-icons/fa'

import './styles.scss'

const StripeCheckout = () => {
  const dispatch = useDispatch()
  const handleSubmit = () => {
    return dispatch(createStripeSession({}))
  }

  return (
    <Card>
      <div className="product">
        <div className="description">
          <h3>Starter plan</h3>
          <h5>$20.00 / month</h5>
        </div>
      </div>
      <div>
        <Button className="checkout-button" onClick={handleSubmit}>
          <FaStripeS className="icon" size="1em" />
          Checkout
        </Button>
      </div>
    </Card>
  )
}

export default StripeCheckout
