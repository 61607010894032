import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { noop, size } from 'lodash'

import DivButton from 'components/div_button'

import '../styles.scss'

const MAX_LENGTH = 25

const ExpandableButtonRow = ({ className, id, name, onClick, selected }) => {
  const exceedsMaxLength = size(name) > MAX_LENGTH
  const displayName = name.slice(0, MAX_LENGTH)
  return (
    <DivButton
      className={classNames('nav-button', 'child', className, { selected })}
      onClick={onClick(id)}
    >
      {displayName}
      {exceedsMaxLength && '...'}
    </DivButton>
  )
}

ExpandableButtonRow.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  selected: PropTypes.bool
}

ExpandableButtonRow.defaultProps = {
  className: undefined,
  onClick: noop,
  selected: false
}

export default ExpandableButtonRow
