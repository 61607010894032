import React from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { destroyJob } from 'modules/jobs/api'
import Button from 'components/button'

const DestroyJob = ({ id }) => {
  const dispatch = useDispatch()
  const handleClick = () => {
    dispatch(destroyJob({ objectId: id }))
  }

  return (
    <div>
      <Button variant="danger" onClick={handleClick}>
        Delete
      </Button>
    </div>
  )
}

DestroyJob.propTypes = {
  id: PropTypes.string.isRequired
}

export default DestroyJob
