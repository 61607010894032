import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import './styles.scss'

const Card = ({ children, className, style }) => {
  const cssClass = classNames('card', className)
  return (
    <div className={cssClass} style={style}>
      {children}
    </div>
  )
}

Card.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  style: PropTypes.shape({})
}

Card.defaultProps = {
  className: undefined,
  style: {}
}

export default Card
