import { generateApi } from 'utils/api'

const {
  createOrganizationDashboard,
  destroyDashboard,
  updateDashboard,
  selectDashboard,
  selectDashboards,
  selectOrganizationDashboards,
  useDashboard,
  useDashboards,
  useOrganizationDashboards
} = generateApi('dashboard', ['organization'])

export {
  createOrganizationDashboard,
  destroyDashboard,
  updateDashboard,
  selectDashboard,
  selectDashboards,
  selectOrganizationDashboards,
  useDashboard,
  useDashboards,
  useOrganizationDashboards
}
