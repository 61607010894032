import React from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'

const Navbar = () => {
  return (
    <div className="navbar">
      <div className="navbar-left">
        <div className="navbar-link">
          <a href="https://treedash.co">Treedash</a>
        </div>
      </div>
      <div className="navbar-right">
        <div className="navbar-link">
          <Link to="/signup">Sign Up</Link>
        </div>
        <div className="navbar-link">
          <Link to="/signin">Sign In</Link>
        </div>
      </div>
    </div>
  )
}

export default Navbar
