import React, { useEffect, useState } from 'react'
import { useQueryParams } from 'utils/query'

import { Navigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { authenticateGoogle } from 'api/integrations'

const ConfirmGoogle = () => {
  const dispatch = useDispatch()
  const [isSuccess, setIsSuccess] = useState(false)
  const params = useQueryParams()
  const confirmAccount = () => {
    dispatch(authenticateGoogle({ ...params })).then(() => {
      setIsSuccess(true)
    })
  }

  useEffect(() => {
    confirmAccount()
  }, [])

  if (isSuccess) {
    return <Navigate to="/settings/connections" />
  }

  return <div>Failed to link google accounts.</div>
}

export default ConfirmGoogle
