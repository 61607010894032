import React from 'react'
import classNames from 'classnames'
import { map, size } from 'lodash'
import { useBoolean } from 'utils/state'
import { useDispatch, useSelector } from 'react-redux'

import { selectCurrentOrganizationId } from 'modules/layout/selectors'
import {
  selectOrganization,
  selectOrganizations
} from 'modules/organizations/api'
import { FaChevronDown, FaChevronUp } from 'react-icons/fa'

import DivButton from 'components/div_button'

import { ButtonDefaults, ButtonProps } from '../constants'
import ExpandableButtonRow from './row'

import '../styles.scss'
import { setCurrentOrganizationId } from 'modules/layout/actions'

const OrganizationButton = ({ className }) => {
  const [expanded, show, close] = useBoolean()
  const dispatch = useDispatch()

  const organizations = useSelector(selectOrganizations)
  const organizationId = useSelector(selectCurrentOrganizationId)
  const currentOrg = useSelector(state =>
    selectOrganization(state, organizationId)
  )
  const { name } = currentOrg
  const hasManyOrgs = size(organizations) > 1

  const handleClick = () => {
    if (hasManyOrgs) {
      expanded ? close() : show()
    }
  }

  const handleUpdateActiveOrg = id => () => {
    dispatch(setCurrentOrganizationId(id))
  }

  const arrowIcons = expanded ? (
    <FaChevronUp className="expand-icon" size="1em" />
  ) : (
    <FaChevronDown className="expand-icon" size="1em" />
  )

  return (
    <div className="expandable">
      <DivButton
        className={classNames('nav-button', className, {
          disabled: !hasManyOrgs
        })}
        onClick={handleClick}
      >
        <div className="org-title">{name}</div>
        {hasManyOrgs && arrowIcons}
      </DivButton>
      {expanded &&
        map(organizations, org => (
          <ExpandableButtonRow
            key={org.id}
            className={className}
            id={org.id}
            name={org.name}
            onClick={handleUpdateActiveOrg}
            selected={org.id === organizationId}
          />
        ))}
    </div>
  )
}

OrganizationButton.propTypes = ButtonProps
OrganizationButton.defaultProps = ButtonDefaults

export default OrganizationButton
