import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import { includes } from 'lodash'

import { MdOutlineAccountCircle, MdPayment } from 'react-icons/md'
import { TbTrees } from 'react-icons/tb'
import DivButton from 'components/div_button'

import { ButtonDefaults, ButtonProps } from './constants'

import './styles.scss'

const MyProfileButton = ({ className }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const selected = includes(pathname, 'profile')

  const handleNavigation = () => {
    navigate('/settings/profile')
  }

  return (
    <DivButton
      className={classNames('nav-button', className, { selected })}
      onClick={handleNavigation}
    >
      <MdOutlineAccountCircle size="1em" />
      <div className="title">My Profile</div>
    </DivButton>
  )
}

MyProfileButton.propTypes = ButtonProps
MyProfileButton.defaultProps = ButtonDefaults

const SubscriptionButton = ({ className }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const selected = includes(pathname, 'subscription')

  const handleNavigation = () => {
    navigate('/settings/subscription')
  }

  return (
    <DivButton
      className={classNames('nav-button', className, { selected })}
      onClick={handleNavigation}
    >
      <MdPayment size="1em" />
      <div className="title">Subscription</div>
    </DivButton>
  )
}

SubscriptionButton.propTypes = ButtonProps
SubscriptionButton.defaultProps = ButtonDefaults

const ConnectionsButton = ({ className }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const { pathname } = location
  const selected = includes(pathname, 'connections')

  const handleNavigation = () => {
    navigate('/settings/connections')
  }

  return (
    <DivButton
      className={classNames('nav-button', className, { selected })}
      onClick={handleNavigation}
    >
      <TbTrees size="1em" />
      <div className="title">Connections</div>
    </DivButton>
  )
}

ConnectionsButton.propTypes = ButtonProps
ConnectionsButton.defaultProps = ButtonDefaults

export { MyProfileButton, SubscriptionButton, ConnectionsButton }
