import React from 'react'
import { Routes, Route } from 'react-router-dom'

import SignIn from 'modules/users/components/auth/signin'
import SignUp from 'modules/users/components/auth/signup'
import Welcome from 'modules/users/components/auth/welcome'
import Home from 'modules/home'

import ConfirmEmail from 'modules/users/components/auth/confirm_email'
import ValidateEmail from 'modules/users/components/auth/validate_email'
import Settings from 'modules/users/components/settings'
import ForgotPassword from 'modules/users/components/auth/forgot_password'
import ResetPassword from 'modules/users/components/auth/reset_password'
import ConfirmShop from 'modules/vendors/shopify/confirm_shop'
import ConfirmGoogle from 'modules/vendors/google/auth/confirm'

import ManageAccount from 'modules/accounts/components/manage'
import ManageAccountMetric from 'modules/account_metrics/components/manage'
import DashboardHome from 'modules/dashboards/components/home'
import ManageDashboardItem from 'modules/dashboard_items/components/manage'

import Admin from 'modules/admin'

import Layout from 'modules/layout'
import AuthLayout from 'modules/layout/auth'

import NotFound from './pages/not_found'
import Forbidden from './pages/forbidden'
import Stripe from 'modules/stripe/components'

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/signin"
        element={
          <Layout>
            <SignIn />
          </Layout>
        }
      />
      <Route
        exact
        path="/signup"
        element={
          <Layout>
            <SignUp />
          </Layout>
        }
      />
      <Route
        exact
        path="/signup/confirm"
        element={
          <Layout>
            <ConfirmEmail />
          </Layout>
        }
      />
      <Route
        exact
        path="/signup/validate"
        element={
          <Layout>
            <ValidateEmail />
          </Layout>
        }
      />
      <Route
        exact
        path="/forgot"
        element={
          <Layout>
            <ForgotPassword />
          </Layout>
        }
      />
      <Route
        exact
        path="/reset"
        element={
          <Layout>
            <ResetPassword />
          </Layout>
        }
      />
      <Route
        exact
        path="/"
        element={
          <AuthLayout requireAuth requireSubscription>
            <Home />
          </AuthLayout>
        }
      />
      <Route
        exact
        path="/settings/:tab"
        element={
          <AuthLayout requireAuth>
            <Settings />
          </AuthLayout>
        }
      />
      <Route
        exact
        path="/shopify/confirm"
        element={
          <AuthLayout requireAuth>
            <ConfirmShop />
          </AuthLayout>
        }
      />
      <Route
        exact
        path="/welcome"
        element={
          <AuthLayout requireAuth>
            <Welcome />
          </AuthLayout>
        }
      />
      <Route
        exact
        path="/subscribe"
        element={
          <AuthLayout requireAuth>
            <Stripe />
          </AuthLayout>
        }
      />
      <Route
        exact
        path="/google/auth"
        element={
          <AuthLayout requireAuth>
            <ConfirmGoogle />
          </AuthLayout>
        }
      />
      <Route
        exact
        path="/accounts/:accountId"
        element={
          <AuthLayout requireAuth requireSubscription>
            <ManageAccount />
          </AuthLayout>
        }
      />
      <Route
        exact
        path="/account_metrics/:accountMetricId"
        element={
          <AuthLayout requireAuth requireSubscription>
            <ManageAccountMetric />
          </AuthLayout>
        }
      />
      <Route
        exact
        path="/dashboards"
        element={
          <AuthLayout requireAuth requireSubscription>
            <DashboardHome />
          </AuthLayout>
        }
      />
      <Route
        exact
        path="/dashboards/:dashboardId"
        element={
          <AuthLayout requireAuth requireSubscription>
            <DashboardHome />
          </AuthLayout>
        }
      />
      <Route
        exact
        path="/dashboard_items/:dashboardItemId"
        element={
          <AuthLayout requireAuth requireSubscription>
            <ManageDashboardItem />
          </AuthLayout>
        }
      />
      <Route
        exact
        path="/settings/admin"
        element={
          <AuthLayout requireAuth requireAdmin>
            <Admin />
          </AuthLayout>
        }
      />
      <Route path="/forbidden" element={<Forbidden />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  )
}

export default AppRoutes
