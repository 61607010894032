import React from 'react'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { get } from 'lodash'

import { useOrganizationDashboards } from 'modules/dashboards/api'
import { useOrganizationAccounts } from 'modules/accounts/api'
import { useOrganizationAccountMetrics } from 'modules/account_metrics/api'
import { selectCurrentOrganizationId } from 'modules/layout/selectors'

import ActionBar from 'modules/layout/action_bar'
import Dashboard from 'modules/dashboards/components/dashboard'

const DashboardHome = () => {
  const params = useParams()
  const organizationId = useSelector(selectCurrentOrganizationId)
  const dashboards = useOrganizationDashboards({ parentId: organizationId })
  const dashboardId = get(params, 'dashboardId') || get(dashboards, '0.id')
  useOrganizationAccounts({ parentId: organizationId })
  useOrganizationAccountMetrics({ parentId: organizationId })
  return (
    <div className="home">
      <ActionBar />
      <Dashboard dashboardId={dashboardId} organizationId={organizationId} />
    </div>
  )
}

DashboardHome.propTypes = {}

export default DashboardHome
