import { filter, get } from 'lodash'
import { parseISO } from 'date-fns'

const isBetween = (d, start, end) => {
  const sIso = parseISO(`${start}T00:00:00`)
  const eIso = parseISO(`${end}T23:59:59`)
  const dIso = parseISO(d)
  return dIso >= sIso && dIso <= eIso
}

const filterCollection = (collection, collectionFilter) => {
  const { start, end } = collectionFilter
  if (start && end) {
    return filter(collection, c => {
      const date = get(c, 'endTime')
      return isBetween(date, start, end)
    })
  }
  return collection
}

export { filterCollection }
